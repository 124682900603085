<template>
    <a-modal v-model:visible="localVisible" :closable=false :maskClosable=false :footer="null">
        <template v-slot:title>Bet Event</template>

        <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :model="event" @finish="onFinish"
            @finishFailed="onFinishFailed">

            <a-form-item label="BET ID: ">{{ event.betId }}</a-form-item>
            <a-form-item label="Description: ">
                <a-textarea v-model:value="event.description" disabled :autoSize="{ minRows: 3, maxRows: 10 }" />
            </a-form-item>
            <a-form-item label="Amount(LGC)">
                <div>
                <span><a-input-number v-model:value="event.amount" min="1" /></span> 
                <span style="margin-left: 10px; color: blue;">Approved:</span>
                <span @click="betAmountClick" style="margin-left: 10px; color: red;">{{betAmount}}</span>
            </div>
            </a-form-item>
            <a-form-item label="Vote">
                <a-radio-group v-model:value="event.vote">
                    <a-radio value="yes">Yes</a-radio>
                    <a-radio value="no">No</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-collapse style="margin-bottom: 20px;">
                <a-collapse-panel key="1" :header="`Total(LGC) Vote Yes: ${getVoteCount(event.edata.useryes)}`">
                    <a-list :dataSource="event.edata.useryes">
                        <a-list-item v-for="(user, index) in event.edata.useryes" :key="index">
                            <a :href="`https://xxxxxx/${user.addr}`" target="_blank">{{ user.addr }}</a>
                            <span style="float: right">{{ user.value }}</span>
                        </a-list-item>
                    </a-list>
                </a-collapse-panel>

                <a-collapse-panel key="2" :header="`Total(LGC) Vote No: ${getVoteCount(event.edata.userno)}`">
                    <a-list :dataSource="event.edata.userno">
                        <a-list-item v-for="(user, index) in event.edata.userno" :key="index">
                            <a :href="`https://xxxxxx/${user.addr}`" target="_blank">{{ user.addr }}</a>
                            <span style="float: right">{{ user.value }}</span>
                        </a-list-item>
                    </a-list>
                </a-collapse-panel>
            </a-collapse>



            <a-form-item>
                <a-button type="primary" html-type="submit">Submit</a-button>
                <a-button style="margin-left: 10px" @click="onCancel">Cancel</a-button>
            </a-form-item>
        </a-form>

    </a-modal>
</template>
  
<script>
import { computed, watch, reactive, toRefs } from 'vue';
import axios from 'axios';
//import moment from 'moment';
import * as web3Utils from '../web3Utils'; // Import the new module
//import { formatBytes32String } from '@ethersproject/strings';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';

export default {
    props: {
        modelValue: Boolean,
        bet: Object,
    },
    setup(props, { emit }) {

        let data = reactive({
            betAmount: computed(() => (store.state.ApproveBalance)),
            event: {
                betId: 1,
                description: '',
                amount: 0,
                vote: 'yes',
                edata: {},
            },
            endDateTime: null,
            localVisible: props.modelValue,
            //betData:    props.bet,
        });

        const store = useStore();
        const betAmountClick = () => {
            console.log('betAmountClick');
            data.event.amount = data.betAmount;
        };

        const voteOnEvent = async () => {
            const betId = data.event.betId;
            const voteValue = data.event.vote === 'yes' ? 1 : 0; // Assume 1 for 'yes' and 0 for 'no'
            const amount = Number(data.event.amount);
            if (isNaN(amount) || amount <= 0) {
                    notification['info']({
                    message: 'INFO',
                    description: ' Amount is not a valid number:'+ data.event.amount,
                    });
                //console.error('Error: Amount is not a valid number:', data.event.amount);
                return;
            }
            if(amount>store.state.ApproveBalance){
                notification['info']({
                    message: 'INFO',
                    description: ' Not Enough Approve Balance:'+ store.state.ApproveBalance,
                    });
                //console.error('Error: Amount is not a valid number:', data.event.amount);
                return;
            }
            console.log("Amount before calling vote:", amount);
            // The user's Ethereum address. Replace this with the actual user address.
            const userAddress = store.state.Account;
            // Update the edata in the database first
            axios.put(`${process.env.VUE_APP_API_URL}/bets/${betId}/update_edata`, {
                vote: data.event.vote,
                amount: amount,
                user: userAddress
            })
                .then((response) => {
                    console.log('Edata updated successfully:', response.data);
                    // Then perform the vote on-chain
                    try {
                        voteEventOnChain(betId, voteValue, amount);
                        console.log(`Voted successfully for event: ${betId} with vote: ${voteValue} and amount: ${amount}`);
                    } catch (error) {
                        console.error('Error voting for event:', error);
                    }
                })
                .catch((error) => {
                    console.error('Error updating edata:', error);
                });
        };

        const voteEventOnChain = async (betId, voteValue, amount) => {
            const signer = await web3Utils.getSigner();
            await web3Utils.vote(signer, betId, voteValue, amount);
        };

        const onCancel = () => {
            console.log('props:::', props.bet.description);
            console.log('onCancel');
            data.localVisible = false;
            emit('update:modelValue', false);
        };
        const onFinish = () => {
            voteOnEvent();
            console.log('voteOnEvent');
            data.localVisible = false;
            emit('update:modelValue', false);

        };
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };
        watch(() => props.modelValue, (newValue) => {
            data.localVisible = newValue;
        });
        watch(() => props.bet, (newValue) => {
            data.event.betId = newValue.id;
            data.event.description = newValue.description;
            data.event.edata = JSON.parse(newValue.edata);
            console.log('watchprops:::', props.bet.description);
        });
        const getVoteCount = (votes) => {
            return votes.reduce((total, vote) => total + Number(vote.value), 0);
        };

        return {
            ...toRefs(data),
            onFinish,
            onFinishFailed,
            onCancel,
            getVoteCount,
            voteOnEvent,
            voteEventOnChain,
            betAmountClick,
            labelCol: {
                style: {
                    width: '100px',
                },
            },
            wrapperCol: {
                span: 18,
            },
        }
    }
}
</script>
