// wallet.js
import axios from 'axios';
import { useWeb3Modal, useWeb3ModalAccount,useDisconnect } from '@web3modal/ethers/vue';
import store from './store'; // 确保这里的路径是正确的
import { watchEffect } from 'vue';


export async function connectWallet() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();

    await open();

    watchEffect(async () => {
      if (isConnected.value) {
        try {
          // 使用 axios 发送请求
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/login`, {
            walletAddress: address.value
          });
          if (response.status === 200) {
            const ResponseData = response.data;
            sessionStorage.setItem('session', ResponseData.session);
            sessionStorage.setItem('walletConnected', 'true');
            sessionStorage.setItem('address', address.value);

            store.commit('setWalletConnected', true);
            store.commit('setAccount', address.value);


          } else {
            console.error('Failed to login with wallet address');
          }
        } catch (error) {
          console.error('Error while fetching session:', error);
        }


        // 这里可以添加获取余额和其他信息的逻辑
      }
    });
}

export async function disconnectWallet() {
    const { close } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    
    try {
      await disconnect();
      await close();
      store.commit('setWalletConnected', false);
      store.commit('setAccount', null);
      
      sessionStorage.setItem('walletConnected', 'false');
      sessionStorage.setItem('address', null);
    } catch (error) {
      console.error('Error disconnecting to wallet:', error);
    }
  }

  export function checkWalletConnection() {
    const isConnected = sessionStorage.getItem('walletConnected') === 'true';
    
    if (isConnected) {
        const address = sessionStorage.getItem('address');
        store.commit('setWalletConnected', isConnected);
        store.commit('setAccount', address);
        console.log(address);
        console.log(isConnected);
      // 可以添加获取钱包地址等逻辑
    }
  }