<template>
  <div class="container">
    <div style="display:flex;flex-direction:column;">
          <img src="../../assets/img/chri.avif" style="width:100%"/>

          <h1 style="color:white">Saturday's Lucky Slots Tournament</h1>
          <h2 style="color:white">Join our exclusive Saturday Slots Tournament to have the perfect opportunity to win one of our extravagant prizes every Saturday from 00:00 UTC to 23:59 UTC.</h2>
          <h2 style="color:white">Be one of our top 50 winningest players and earn a share of the HUGE</h2>
          <h2 style="color:white">Prize Pool. Play now and show you belong at the top!</h2>
          <h2 style="color:white">How to enter : </h2>
          <h2 style="color:white">Step 1: Kick Off Your Weekend </h2>
          <h2 style="color:white">: Visit your loyalty widget</h2>
          <h2 style="color:white">Step 2: Dive into the Excitement</h2>
          <h2 style="color:white"> : Click on the tournament icon and “Join Tournament”</h2>
          <h2 style="color:white">Step 3: Compete for Prizes </h2>
          <h2 style="color:white">: Each Saturday from 00:00 UTC, bet with real money on any eligible slots with a min of €0.25 (or currency equivalent) per round </h2>
          <h2 style="color:white">Step 4: Outperform your oppostion </h2>
          <h2 style="color:white">! : Collect points with every winning bet and climb up the leaderboard! </h2>

          <h2 style="color:white">How to Win : </h2>
          <h2 style="color:white">- Your ranking is determined by the total win / bet multiplier score during the tournament. This means, that each time you win a qualifying bet, you earn points and rise on the leaderboard!  </h2>
          <h2 style="color:white">- You earn 1 point per multiplier value of your winning bet. The higher your multiplier is the more points you get. </h2>
          <h2 style="color:white">- Once the tournament finishes at 23:59 UTC, If you are one of our 50 lucky winners, we will credit your account with your prize  </h2>
          <h2 style="color:white">automatically. </h2>
          <h2 style="color:white">Visit the tournament section anytime to see your ranking and points score! </h2>
          <h2 style="color:white">Point System Example :  </h2>
          <h2 style="color:white">You bet €100 → You win €1000 → Your win multiplier is 10x (1000/100=10) → You earn 10 Points. </h2>


    </div>
  </div>

</template>
<script>

export default {
  components: {

  },
  setup() {
  }
}
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 每行显示三列 */
  /* grid-gap: 10px;图片之间的间隔 */
  grid-row-gap: 20px;
  /* 行间隔 */
  grid-column-gap: 10px;
  /* 列间隔是行间隔的两倍 */
  padding: 4%;
  max-width: 768px;

}

.image-grid img {
  width: 100%;
  /* 图片宽度自动适应父元素（每列）的宽度 */
  height: auto;
  /* 图片高度根据宽度自动调整，保持纵横比例 */
}

:deep(.slick-slide) {
  text-align: center;
  height: auto;
  /* 自动适应高度 */
  background: #000000;

}

:deep(.slick-slide img) {
  max-width: 100%;
  /* 图片宽度自动适应容器，不超过100% */
  max-height: 100vh;
  /* 图片高度最大为屏幕高度的100% */
}

:deep(.slick-slide h3) {
  color: #000000;
}

/* 使轮播图全屏宽度，并在移动设备上占满整个视口 */
@media (max-width: 768px) {
  :deep(.slick-slider) {
    width: 100vw;
    /* 宽度占满视口 */
    margin: 0;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-list) {
    margin: 10px;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-slide) {
    height: 220px;
    /* 每个轮播图幻灯片占满整个屏幕高度 */
  }

  :deep(.slick-slide img) {
    max-height: 100vh;
    /* 图片高度最大为屏幕高度的100% */
  }
}

.lgcintro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lgcintro .image {
  width: 40%;
  height: auto;
}

.lgcintro .text {
  width: 40%;
}

@media (max-width: 768px) {
  .lgcintro {
    flex-direction: column;
  }

  .lgcintro .image {
    width: 100%;
  }

  .lgcintro .text {
    width: 100%;
  }
}

.containerchart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: black;
}

:deep .ant-table {
  /*border: none;  隐藏表格外部边框 */
  margin: auto; /* 表格居中 */
  max-width: 95%; /* 表格最大宽度，保持与边界一定距离 */
}
/* 使用 ::v-deep 操作符 */
:deep .ant-table-thead > tr > th,
:deep .ant-table-tbody > tr > td {
  background: #000000 ; /* 统一设置背景色 */
  color: white ; /* 设置文字颜色 */
  border: none ; /* 隐藏单元格边框 */
}

:deep .ant-table-tbody > tr:nth-child(odd) > td {
  background: #1c1c1c ; /* 奇数行的背景色 */
}

:deep .ant-table-tbody > tr:nth-child(even) > td {
  background: #000000 ; /* 偶数行的背景色 */
}



</style>