<template>
    <div class="container">
        <div>
            <div font-size="18px" color="text" style="color: rgb(23, 243, 221);
          font-weight: bold;
          margin-top: 50px;
          line-height: 1.5;
          font-family: ExtraBold;
          font-size: xx-large;
          text-align: center;">Staking to Earn</div>
        </div>

        <a-card class="rounded-card" :style="{margin: '20px', width: '95%', maxWidth: '800px' }">

            <a-row :gutter="16">
            <a-col :span="24">
                <a-radio-group v-model:value="selectedCurrency" class="custom-radio-group">
                    <a-radio-button value="ROSIE">ROSIE</a-radio-button>
                    <a-radio-button value="USDT">USDT</a-radio-button>
                    <a-radio-button value="BTC">BTC</a-radio-button>
                    <a-radio-button value="DOGE">DOGE</a-radio-button>
                </a-radio-group>
            </a-col>
            </a-row>

            <div style="display: flex;justify-content: center;margin-top: 25px;margin-bottom: 20px;">
                <div style="width: 100%;height: 2px;background-color: yellow"></div>
            </div>
            <!-- Title -->
            <a-row :gutter="16">
                <a-col :span="24">
                    <h2 class="centered-text">{{selectedCurrency}} Staking Statistics</h2>
                </a-col>
            </a-row>
            <!--
            <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 10px;">
                <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
            </div>
            -->
            <!-- Row with 2 columns without split -->
            <a-row :gutter="16">
                <a-col :xs="24" :sm="24">
                    <div style="display: flex;justify-content: space-between;">
                        <div
                            style="display:flex;flex-direction: column;justify-content: center;width: 50%;text-align: center;">
                            <span style="font-size: large;color: yellow;">Current Staking</span>
                            <span style="font-size: large;font-weight: bold;"> {{
                                stakingStats[selectedCurrency].currentStaking }}</span>
                        </div>
                        <div
                            style="display:flex;flex-direction: column;justify-content: center;width: 50%;text-align: center;">
                            <span style="font-size: large;color: yellow;">APY</span>
                            <span style="font-size: large;font-weight: bold;">{{ stakingStats[selectedCurrency].apy
                            }}</span>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 10px;">
                <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
            </div>

            <a-row :gutter="16">
                <a-col :xs="24" :sm="12">
                    <div id="c1"
                        style="display:flex;flex-direction: column;justify-content: center; align-items: center; width: 100%;text-align: center;">
                        <span style="width: 100%; text-align: left;font-size: large;color: yellow;">My Staking</span>
                        <a-image :width="100" :preview="false" :src="stakingImages[selectedCurrency]" />
                        <span style="font-size: large;font-weight: bold;">0,000.00 {{selectedCurrency}}</span>
                        <span><a-button shape="round" ghost
                                @click="startStaking" style="font-size:20px; color:yellow; margin-top: 20px; width: 300px;height: 50px; font-weight:bold">Start
                                Staking</a-button></span>
                    </div>
                </a-col>
                <a-col :xs="24" :sm="12">
                    <div id="c2"
                        style="display:flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;text-align: center;">
                        <span style="width: 100%; text-align: left;font-size: large;color: yellow;">My Rewards</span>
                        <a-image :width="100" :preview="false" :src="stakingImages[selectedCurrency]" />
                        <span style="font-size: large;font-weight: bold;">0,000.00 {{selectedCurrency}}</span>
                        <span><a-button shape="round" ghost
                                @click="claimRewards" style="font-size:20px; color:yellow; margin-top: 20px; width: 300px;height: 50px; font-weight:bold">Claim
                                Rewards</a-button></span>
                    </div>
                </a-col>
            </a-row>
        </a-card>


        <div style="max-width: 800px; width: 100%; text-align: left;color: yellow;">
            1. The pledger's income calculation starts at UTC+0, and the pledge income is distributed for the previous 24
            hours
            at UTC+0 every day.
        </div>
        <div style="max-width: 800px; width: 100%; text-align: left;color: yellow;">
            2. Earnings pledges are different from game and event creation pledges, and they belong to different smart
            contract
            management.
        </div>
        <div style="max-width: 800px; width: 100%; text-align: left;color: yellow;">
            3. The pledge and recharge time will be delayed according to network congestion and Gas level.
        </div>
        <div style="max-width: 800px; width: 100%; text-align: left;color: red;font-weight: bold;margin-top: 20px;">
            Note: No god can guarantee the rate of return on staking.
        </div>

        <div style="font-size: large;margin-top: 30px;">One Week Earned</div>




    </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
//import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';


export default {
    components: {

    },
    setup() {
        let data = reactive({
            selectedCurrency: 'ROSIE', // 默认选中的货币类型
            // 预定义所有可能的图片路径
            stakingImages: {
                ROSIE: require('../../assets/img/logos/roses2.png'),
                USDT: require('../../assets/img/usdt-logo-300.png'),
                BTC: require('../../assets/img/Bitcoin.svg'),
                DOGE: require('../../assets/img/Dogecoin_Logo.png'),
            },
            stakingStats: {
                ROSIE: { currentStaking: "100", apy: "100%" },
                USDT: { currentStaking: "103453451.65", apy: "10451%" },
                BTC: { currentStaking: "102", apy: "102%" },
                DOGE: { currentStaking: "103", apy: "103%" },
            },
        });

        const startStaking = () => {
            message.info('Test Version');
        };

        const claimRewards = () => {
            message.info('Test Version');
        };
        onMounted(() => {
            console.log(data.stakingStats); // 查看初始数据
        });
        return {
            ...toRefs(data),
            startStaking,
            claimRewards,
        };
    }
}
</script>
  
<style scoped>


/* 定制化 Radio Group 样式 */
.custom-radio-group {
    width: calc(100%); /* a-card 的宽度减去两侧的边距 */
    margin: auto; /* 水平居中 */
    text-align: center; /* 文字居中 */
}

::v-deep .custom-radio-group .ant-radio-button-wrapper {
    background-color: black; /* 按钮背景颜色 */
    color: yellow; /* 文字颜色 */
    font-weight: bold; /* 文字加粗 */
    border-color: black; /* 边框颜色 */
}

::v-deep .custom-radio-group .ant-radio-button-wrapper-checked {
    background-color: black; /* 选中状态下的背景颜色 */
    border-color: yellow; /* 选中状态下的边框颜色 */
    color: yellow; /* 选中状态下的文字颜色 */
}


.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: black;
}

.rounded-card {
    border-radius: 15px;
    background-color: #000000;
    color: white;
    /* Apply round corner */
}

.a-radio-button {
    margin-right: 8px;
    border-radius: 5px;
    /* 圆角按钮 */
    /* 更多样式调整 */
}

.centered-text {
    text-align: center;
    color: white;
}

.lefted-text {
    text-align: left;
}

.righted-text {
    text-align: right;
}

/* ID-based selectors for the divs */
#c1 {
    border-right: 1px solid #ddd;
    border-bottom: 0;
}

#lchart {
    width: 800px;
}

/* Media query for screens smaller than 768px (usually mobile) */
@media screen and (max-width: 768px) {
    #c1 {
        border-right: 0;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }

    #lchart {
        width: 100%;
    }
}</style>