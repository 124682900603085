import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../pages/homepage/HomePage.vue'
import EventsPage from '../pages/eventspage/EventsPage.vue'
import GamesPage from '../pages/gamespage/GamesPage.vue'
import EarnPage from '../pages/earnpage/EarnPage.vue'
import AifiPage from '../pages/aifipage/AifiPage.vue'
import DaoPage from '../pages/daopage/DaoPage.vue'
import PledgePage from '../pages/pledgepage/PledgePage.vue'

import Ads1Page from '../pages/ads/Ads1Page.vue'
import Ads2Page from '../pages/ads/Ads2Page.vue'
import Ads3Page from '../pages/ads/Ads3Page.vue'

import PrivacyPolicyPage from '../pages/infos/PrivacyPolicyPage.vue'

const routes = [
  {
    path: '/privacypolicy',
    name: 'Privacypolicy',
    component: PrivacyPolicyPage
  },
  {
    path: '/ads1',
    name: 'Ads1',
    component: Ads1Page
  },
  {
    path: '/ads2',
    name: 'Ads2',
    component: Ads2Page
  },
  {
    path: '/ads3',
    name: 'Ads3',
    component: Ads3Page
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsPage
  },
  {
    path: '/games',
    name: 'Games',
    component: GamesPage
  },
  {
    path: '/earn',
    name: 'Earn',
    component: EarnPage
  },
  {
    path: '/aifi',
    name: 'Aifi',
    component: AifiPage
  },
  {
    path: '/dao',
    name: 'Dao',
    component: DaoPage
  },
  {
    path: '/pledge',
    name: 'Pledge',
    component: PledgePage
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
