import { createStore } from 'vuex';
import { connectWallet } from './wallet'; // 引入 connectWallet 函数


export default createStore({
  state: {
    walletConnected: false,
    Account: '',
    Balance: 0,
    IsAdmin: false,
  },
  mutations: {
    setWalletConnected(state, walletConnected) {
      state.walletConnected = walletConnected;
    },
    setAccount(state, account) {
      state.Account = account;
    },
    setBalance(state, balance) {
      state.Balance = balance;
    },
    setIsAdmin(state, isAdmin) {
      state.IsAdmin = isAdmin;
    },
  },
  actions: {
    async connectToWallet() {
      await connectWallet();
    },

  },
});
