<template>
  <div class="container">

    <div style="max-width: 1000px; width: 100%;">
      <div>
        <div style="color: rgb(23, 243, 221);
                    font-weight: bold;
                    margin-top: 50px;
                    line-height: 1.5;
                    font-family: 'Arial', sans-serif;
                    font-size: 2em;
                    text-align: center;">The List of LetEvents</div>
      </div>
      <a-tabs v-model="activeTab" @change="handleTabChange">
        <a-tab-pane key="beting" tab="Beting">
          <a-list :dataSource="betingData" :grid="gridConfig">
            <template #renderItem="{ item }">
              <a-list-item :key="item.id">
                <a-card :title="item.id" :hoverable="true"
                  style="height: 250px; margin-bottom: 16px;border-radius: 10px; display: flex; flex-direction: column; justify-content: space-between;">

                  <p>End Date: {{ formatDate(item.end_time) }}</p>

                  <div style="display: flex;justify-content: center;margin-top: 5px;margin-bottom: 5px;">
                    <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
                  </div>
                  <p style="font-weight: bold; height: 80px; word-wrap:break-word; ">{{ item.description }}</p>


                  <div style="display: flex; justify-content: center; align-items: center;width: 100%; margin-top: auto;">
                    <a-button :disabled="false" style="width: 300px; font-weight: bold;" shape="round" type="primary"
                      @click="openBetModal(item.id)">Bet->{{ item.id }}</a-button>
                  </div>
                </a-card>
              </a-list-item>

            </template>
          </a-list>
        </a-tab-pane>

        <a-tab-pane key="waiting" tab="Waiting">
          <a-list :dataSource="waitingData" :grid="gridConfig">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-card :title="item.id" :hoverable="true" :class="'card-waiting'"
                  style="height: 200px; margin-bottom: 16px;border-radius: 10px; display: flex; flex-direction: column; justify-content: space-between;">
                  <p>End Date: {{ formatDate(item.end_time) }}</p>
                  <div style="display: flex;justify-content: center;margin-top: 5px;margin-bottom: 5px;">
                    <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
                  </div>
                  <p style="font-weight: bold; height: 80px; word-wrap:break-word;">{{ item.description }}</p>
                </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-tab-pane>

        <a-tab-pane key="archive" tab="Archive">
          <a-list :dataSource="archiveData" :grid="gridConfig">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-card :title="item.id" :hoverable="true" :class="item.result ? 'card-yes' : 'card-no'"
                  style="height: 200px; marginBottom: 16px; borderRadius: 10px; display: flex; flexDirection: column; justifyContent: space-between; ">
                  <p>End Time: {{ formatDate(item.end_time) }}</p>
                  <div style="display: flex;justify-content: center;margin-top: 5px;margin-bottom: 5px;">
                    <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
                  </div>
                  <p style="font-weight: bold; height: 80px; word-wrap:break-word;">{{ item.description }}</p>
                </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-tab-pane>

      </a-tabs>

      
      <!--
      <a-modal v-model:visible="winnerModalVisible" :title="winnerModalData.description" ok-text="Close"
        @ok="handleCloseWinnerModal">
        <p>{{ winnerModalData.description }}</p>
        <a-list :dataSource="winnerList">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-card :title="item.addr">
                <p>Winning amount: {{ item.value }}</p>
              </a-card>
            </a-list-item>
          </template>
        </a-list>

      </a-modal>
      -->
    </div>
    <bet-event-modal 
    v-model="showBetModal"
    :bet="betModalData"
    ></bet-event-modal>

    <a-pagination :current="currentPage" :total="totalBets" :defaultPageSize="paginationConfig.pageSize"
      @change="handlePageChange"></a-pagination>

    <div style="font-size: large;font-weight: bold; text-align: left;max-width: 800px; width: 100%; margin-bottom: 20px;">
      Rules
    </div>

    <div style="max-width: 800px; width: 100%; text-align: left;">
      1. Stakers can initiate events and get the developer's income rights in the event.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      2. The current result settlement event for all events must be 24:00 (UTC) of the date. Results are collated against
      arbitrators or decentralized event processing links such as ChainLink.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      3. For a period of time, event initiators are managed by managers (managers also obtain tokens and pledge to
      initiate events like all users). After the assignment of the community role mechanism is completed, the public event
      will be launched.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      4. After opening the public event initiation function and the security is fully verified, the contract will be
      open-sourced.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;color: red;font-weight: bold;margin-top: 20px;">
      Note: The time period for some events to produce results is very long, so the constraint condition is that the
      result verification of all events needs to be less than 1 month.
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { reactive, onMounted, toRefs } from 'vue';
//import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import axios from 'axios';
import BetEventModal from '../../components/BetEventModal.vue';
import { notification } from 'ant-design-vue';

export default {
  components: {
    BetEventModal,
    //CheckCircleOutlined,
    //CloseCircleOutlined,
  },
  setup() {
    let data = reactive({
      showBetModal: false,

      activeTab: 'beting',
      //betModalVisible: false,
      //winnerModalVisible: false,
      betModalData: {},
      //winnerModalData: {},
      //betOption: '',
      //betAmount: 1,
      betingData: [],
      waitingData: [],
      archiveData: [],
      //betHistory: [],
      //winners: [],
      //winnerList: [],
      currentPage: 1,
      totalPages: 1,
      totalBets: 1,
      gridConfig: {
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
        xxxl: 3,
        xxxxl: 3,
        xxxxxl: 3,
      },
      paginationConfig: {
        pageSize: window.innerWidth > 768 ? 15 : 5,
      },
    });

    const store = useStore();

    const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // 因为 JavaScript 期望的时间戳是以毫秒为单位的，所以需要乘以 1000
    return date.toLocaleDateString(); // 输出格式：MM/DD/YYYY
    };

    const fetchData = async (action, page = 1) => {
      const device = window.innerWidth > 768 ? 'pc' : 'mobile'
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/bets?action=${action}&page=${page}&device=${device}`)
      data.totalPages = response.data.total_pages; // 从后端响应中获取总页数
      data.currentPage = page; // 设置当前页码
      data.totalBets = response.data.total_bets;
      //console.log("action:", action, "currentPage:", data.currentPage, "totalPages:", data.totalPages)
      return response.data.bets
    };

    const getBackgroundColor = (result) => {
      return result ? 'lightgreen' : 'salmon'; // 使用你喜欢的颜色替换'lightgreen'和'salmon'
    };
    const handleCloseWinnerModal = () => {
      //data.winnerModalVisible = false
    };
    window.addEventListener('resize', () => {
      data.paginationConfig.pageSize = window.innerWidth > 768 ? 15 : 5;
    });

    const handlePageChange = async page => {
      // 根据新的页码重新获取数据
      switch (data.activeTab) {
        case 'beting':
          data.betingData = await fetchData('beting', page)
          break
        case 'waiting':
          data.waitingData = await fetchData('waiting', page)
          break
        case 'archive':
          data.archiveData = await fetchData('archive', page)
          break
        default:
          break
      }
    }

    const handleTabChange = async key => {
      data.activeTab = key
      switch (key) {
        case 'beting':
          data.betingData = await fetchData('beting')
          break
        case 'waiting':
          data.waitingData = await fetchData('waiting')
          break
        case 'archive':
          data.archiveData = await fetchData('archive')
          break
        default:
          break
      }
    }

    const openBetModal = async (betid) => {
    if (!store.state.walletConnected) {
        notification['info']({
            message: 'INFO',
            description: 'Please connect your wallet first.',
        });
        return;
    }
    console.log("openBetModal--->>>betid:", betid);
    data.betModalData = data.betingData.find(bet => bet.id === betid);
    console.log("openBetModal--->>>data.betModalData:", data.betModalData);

    // 如果edata存在
    if (data.betModalData.edata) {
        // 解析为JavaScript对象
        let edataObj = JSON.parse(data.betModalData.edata);

        // 过滤addr不为'0'的对象
        if (edataObj.useryes) {
            edataObj.useryes = edataObj.useryes.filter(user => user.addr !== '0');
        }
        if (edataObj.userno) {
            edataObj.userno = edataObj.userno.filter(user => user.addr !== '0');
        }

        // 转换回JSON字符串
        data.betModalData.edata = JSON.stringify(edataObj);
    }

    data.showBetModal = true;
}


    //const handleBet = () => {
      // Handle bet logic here
    //  console.log(`Bet ${data.betAmount} on ${data.betOption}`)
    //  data.betModalVisible = false
    //}

    //const openWinnerModal = data => {
    //data.winnerModalData = data
    //data.winnerModalVisible = true
    //}
    onMounted(async () => {
      // Fetch data when component is mounted
      data.betingData = await fetchData('beting')
    })


    return {
      ...toRefs(data),
      handleTabChange,
      openBetModal,
      //handleBet,
      //openWinnerModal,
      handleCloseWinnerModal,
      getBackgroundColor,
      fetchData,
      handlePageChange,
      formatDate,
    }
  }
}
</script>

<style scoped>
/* Add your styles here */


.card-waiting {
  background-color: rgb(232, 247, 121);
  position: relative;
}

.card-waiting::before {
  content: "Wait";
  position: absolute;
  font-size: 10em;
  opacity: 0.4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.card-yes {
  background-color: lightgreen;
  position: relative;
}

.card-yes::before {
  content: "YES";
  position: absolute;
  font-size: 10em;
  opacity: 0.4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.card-no {
  background-color: salmon;
  position: relative;
}

.card-no::before {
  content: "NO";
  position: absolute;
  font-size: 10em;
  opacity: 0.2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ant-tabs,
.ant-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}


@media (max-width: 768px) {
  .ant-list-pagination {
    display: block;
  }
}

@media (min-width: 769px) {
  .ant-list-pagination {
    display: none;
  }
}
</style>