import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router'

import store from './store' // 这里的路径应该指向你的 store 文件

import ECharts from 'vue-echarts'
import { use } from "echarts/core";

import { Buffer } from 'buffer';

// 手动引入 ECharts 各模块来减小打包体积
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  LineChart,
  PieChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components'

import * as echarts from 'echarts';

use([
    CanvasRenderer,
    BarChart,
    LineChart,
    PieChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
]);

const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app.use(Antd);
app.use(router);

app.use(store); // 使用 store

// 全局注册组件（也可以使用局部注册）
app.component('v-chart', ECharts)

window.Buffer = Buffer;

app.mount('#app');
