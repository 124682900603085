<template>
  <div class="wallet-container">


    <a-tabs centered v-model:activeKey="activeKey" @change="handleChange">
      <a-tab-pane key="Deposit" tab="Deposit">

        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">

          <div style="margin: 5px; display: flex; align-items: center;">
            <a-button ghost @click="connectWallet1" style="font-weight: bold; color: white; margin-bottom: 10px;">
              <LinkOutlined style="color: blue;" /> Use WalletConnect
            </a-button>
          </div>
          <!--
          <div style="margin: 5px; display: flex; align-items: center;">
            <a-button ghost @click="disconnectWallet" style="font-weight: bold; color: white; margin-bottom: 10px;">
              <LinkOutlined style="color: blue;" /> disconnectWallet
            </a-button>
          </div>
          -->
          <a-select v-model:value="selectedCurrency" class="currency-select" @change="updateCurrencyInfo">
            <a-select-option v-for="currency in currencies" :key="currency.key" :value="currency.key">
              <img :src="currency.icon" class="currency-icon" /> {{ currency.label }}
              <!--
              <template #label>
                <img :src="currency.icon" class="currency-icon" /> {{ currency.label }}
              </template>
              -->

            </a-select-option>
          </a-select>

          <p class="minimum-deposit">Minimum deposit amount:{{ currentCurrency.minDeposit }}</p>

          <div class="address-container">
            <p class="address-title">Your {{ currentCurrency.label }} Deposit address</p>


            <a-textarea :value="currentCurrency.address" readonly class="address-input" />

            <a-button @click="copyAddress" class="full-width-button">Copy</a-button>

          </div>

          <p class="deposit-warning">Only send {{ currentCurrency.label }} to this address and require {{ currentCurrency.confirmations }}confirmations.Values sent below the minimum deposit amount or to an incorrect address will be lost.</p>

          <!-- Replace with actual QR Code component -->
          <div class="qr-code">
            <img :src="generateQRCode(currentCurrency.address)" alt="QR Code" class="qr-image" />
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="Withdraw" tab="Withdraw">
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">

          <a-select v-model:value="selectedCurrency" class="Withdraw-currency-select" @change="updateCurrencyInfo">
            <a-select-option v-for="currency in currencies" :key="currency.key" :value="currency.key">
              {{ currentCurrency.owned }}&nbsp;
              {{ currency.label }}&nbsp;
              <img :src="currency.icon" class="currency-icon" />
            </a-select-option>
          </a-select>

          <p class="minimum-deposit">Minimum withdrawal amount:{{ currentCurrency.minWithdraw }}</p>


          <a-form @finish="handleWithdraw">
            <a-form-item label="address">
              <a-input v-model:value="WithdrawForm.address" placeholder="Enter your address" />
            </a-form-item>
            <a-form-item label="amount">
              <a-input v-model:value="WithdrawForm.count" placeholder="Enter your amount">
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button class="full-width-button">Activate 2FA</a-button>
              <a-button type="primary" html-type="submit" class="full-width-button">Withdraw</a-button>
            </a-form-item>
          </a-form>
          <p class="withdraw-warning">Requesting a withdrawal will mean you will lose all winnings. 2FA is mandatory for withdrawals.</p>
          <p class="withdraw-warning">You must enable 2-step verification to withdraw money</p>
        </div>


      </a-tab-pane>
      <a-tab-pane key="Buy" tab="Buy">
        <a-empty :image="simpleImage" />

      </a-tab-pane>
    </a-tabs>
  </div>
</template>
  
<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { LinkOutlined } from '@ant-design/icons-vue';
import { Empty, Tabs, Select, Button, Textarea } from 'ant-design-vue';
//import { connectWallet, disconnectWallet, state } from '../WalletConnectProvider';
//import Web3Modal from 'web3modal';
//import WalletConnectProvider from '@walletconnect/web3-provider';
//import { ethers } from 'ethers';
//import { Web3Provider } from "@ethersproject/providers";


import {
  useWeb3Modal,
  //useWeb3ModalEvents,
  //useWeb3ModalState,
  useDisconnect,
  useWeb3ModalAccount
} from '@web3modal/ethers/vue'


export default {
  data() {
    return {
      // state, // Web3Modal 状态
    };
  },
  components: {
    'a-empty': Empty,
    LinkOutlined,
    'a-tabs': Tabs,
    'a-tab-pane': Tabs.TabPane,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-button': Button,
    //'a-input': Input,
    //'a-input-group': InputGroup,
    'a-textarea': Textarea,
  },
  setup() {


    /*
    LGC: require('../../assets/img/LILI.png'),
                USDT: require('../../assets/img/usdt-logo-300.png'),
                BTC: require('../../assets/img/Bitcoin.svg'),
                DOGE: require('../../assets/img/Dogecoin_Logo.png'),
  */
    //const provider = ref(null);


    // const store = useStore(); // 获取 Vuex store

    const { open, close } = useWeb3Modal()
    //const state = useWeb3ModalState(); // 获取 Web3Modal 状态
    //const events = useWeb3ModalEvents(); // 获取 Web3Modal 事件


    const { disconnect } = useDisconnect();
    const { address, chainId, isConnected } = useWeb3ModalAccount();


    /*
    watch(events, (newEvents) => {
      console.log('events changed:', JSON.stringify(events, null, 2));
      console.log('newEvents changed:', JSON.stringify(newEvents, null, 2));
    });
    // 观察 state 对象，当钱包连接状态改变时执行操作
    watch(state, (newState) => {
      console.log('state changed:', JSON.stringify(state, null, 2));
      console.log('newState changed:', JSON.stringify(newState, null, 2));
    });
    */
    // 观察 isConnected 对象，当钱包连接状态改变时执行操作
    watch(isConnected, (ConnectState) => {

      if(ConnectState == false){//断开连接
        console.log('wallet  disconnect.');

      }
      if(ConnectState == true){//连接成功
        console.log('address changed:', address.value);
        console.log('chainId changed:', chainId.value);
        //clearInterval(interval.value);
      }
      /*
      console.log('isConnected changed:', JSON.stringify(isConnected, null, 2));
      console.log('ConnectState changed:', JSON.stringify(ConnectState, null, 2));

      console.log('address changed:', JSON.stringify(address, null, 2));
      console.log('chainId changed:', JSON.stringify(chainId, null, 2));
      */
    });

    const connectWallet1 = () => {
      open();

    };
    const disconnectWallet = () => {
      close();
      disconnect();
    };

    onMounted(() => {
      // interval.value = setInterval(checkWalletConnection, 1000);
      // if (!modal.state.isConnected) {
      //   interval.value = setInterval(checkWalletConnection, 1000);
      // }
    });

    onUnmounted(() => {
    });


    const generateQRCode = (address) => {
      // 模拟二维码生成，您需要替换为实际的二维码生成逻辑
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${address}`;
      //return currentCurrency.value.qrCode; // 使用已有的QR Code图片
    };

    const WithdrawForm = ref({
      address: '',
      count: '',
    });
    const activeKey = ref('Deposit');
    const selectedCurrency = ref('ROSIE');
    const currencies = ref([
      {
        key: 'ROSIE',
        label: 'ROSIE',
        icon: require('@/assets/img/logos/roses2.png'), // 确保此路径与您的项目结构匹配
        minDeposit: '0.01 ROSIE',
        address: '0x9638E1A2dc169de2140B463ed14B2c20639f522F1',
        confirmations: 12,
        minWithdraw: '0.01 ROSIE',
        owned: 112.123456789012,
      },
      {
        key: 'USDT',
        label: 'USDT',
        icon: require('@/assets/img/usdt-logo-300.png'), // 确保此路径与您的项目结构匹配
        minDeposit: '0.01 USDT',
        address: '0x9638E1A2dc169de2140B463ed14B2c20639f522F2',
        confirmations: 12,
        minWithdraw: '0.01 USDT',
        owned: 112.123456789012,
      },
      {
        key: 'BTC',
        label: 'BTC',
        icon: require('@/assets/img/Bitcoin.svg'), // 确保此路径与您的项目结构匹配
        minDeposit: '0.01 BTC',
        address: '0x9638E1A2dc169de2140B463ed14B2c20639f522F3',
        confirmations: 12,
        minWithdraw: '0.01 BTC',
        owned: 112.123456789012,
      },
      {
        key: 'DOGE',
        label: 'DOGE',
        icon: require('@/assets/img/Dogecoin_Logo.png'), // 确保此路径与您的项目结构匹配
        minDeposit: '0.01 DOGE',
        address: '0x9638E1A2dc169de2140B463ed14B2c20639f522F4',
        confirmations: 12,
        minWithdraw: '0.01 DOGE',
        owned: 112.123456789012,
      },

      // ... other currencies
    ]);
    // 使用计算属性来获取当前货币信息
    const currentCurrency = computed(() => {
      return currencies.value.find(currency => currency.key === selectedCurrency.value) || currencies.value[0];
    });
    watch(selectedCurrency, (newVal) => {
      const currency = currencies.value.find(currency => currency.key === newVal);
      if (currency) {
        currentCurrency.value = currency;
      }
    });
    const handleChange = (key) => {
      activeKey.value = key;
    };

    const updateCurrencyInfo = (value) => {
      currentCurrency.value = currencies.value.find(currency => currency.key === value);
    };

    const copyAddress = () => {
      navigator.clipboard.writeText(currentCurrency.value.address).then(() => {
        // Show success message
      }).catch(err => {
        console.error(err);
        // Show error message
      });
    };

    return {
      WithdrawForm,
      activeKey,
      selectedCurrency,
      currencies,
      currentCurrency,
      handleChange,
      //connectWallet,
      updateCurrencyInfo,
      copyAddress,
      generateQRCode,
      connectWallet1,
      disconnectWallet
    };
  }
};
</script>
<style scoped>
.wallet-container {
  background-color: black;
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.wallet-connect-btn {
  margin-bottom: 20px;
  color: white;
}

.currency-select {
  width: 150px;
  margin-bottom: 20px;
}

.Withdraw-currency-select {
  width: 250px;
  margin-bottom: 20px;
}

.currency-icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.minimum-deposit {
  color: gray;
  margin-bottom: 20px;
}

.address-container {
  text-align: center;
  /* 居中地址标题 */
  margin-bottom: 20px;

}

.address-title {
  margin-bottom: 10px;
  /* 为标题和输入框之间添加一些空间 */
}

/* 定制 a-textarea 内部的文本框样式 */
::v-deep .address-input {
  color: white;
  /* 设置文本颜色为白色 */
  background-color: black;
  /* 设置背景颜色为黑色 */
  border: none;
  /* 移除边框 */
  padding: 10px;
  /* 设置内边距 */
  white-space: pre-wrap;
  /* 允许换行 */
  overflow-wrap: break-word;
  /* 在长单词或URL不可避免的地方换行 */
}

.deposit-warning {
  margin-bottom: 20px;
  color: white;
  font-size: smaller;
  text-align: center;
}

.withdraw-warning {
  margin-top: 20px;
  color: white;
  font-size: smaller;
  text-align: center;
}

.qr-code {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  margin-top: 20px;

}

.qr-image {
  width: 150px;
  height: 150px;
}

.full-width-button {
  width: 100%;
  /* 按钮宽度拉满 */
  background-color: #f1c24c;
  /* 按钮背景颜色 */
  color: black;
  /* 文字颜色 */
  margin-top: 10px;
}

.form-style {
  margin-top: 20px;
}

/* 使用 ::v-deep 选择器定制 Ant Design Vue Form Item 组件的标签样式 */
::v-deep .ant-form-item-label>label {
  color: white;
  /* 设置标签字体颜色为白色 */
}

/* 定制 Tabs 标签的样式 */
/* 定制 Tabs 标签的样式 */
/* 使用 ::v-deep 选择器定制 Ant Design Vue Tabs 组件样式 */
::v-deep .ant-tabs-tab {
  color: gray;
  font-weight: bold;
  font-size: large;
}

::v-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

::v-deep .ant-tabs-content {
  color: white;
}


/* 定制 a-select 下拉框的背景和文字颜色 */
::v-deep .ant-select-selector {
  background-color: #343434 !important;
  /* 设置背景色为灰色 */
  color: white !important;
  /* 设置文字颜色为白色 */
}

/* 定制 a-select 选项的文字颜色 */
::v-deep .ant-select-item-option-content {
  color: white !important;
  /* 设置文字颜色为白色 */
  font-weight: bold !important;
  /* 设置文字为加粗 */
}

/* 定制下拉箭头的颜色 */
::v-deep .ant-select-arrow {
  color: white !important;
}

/* 定制下拉框边框 */
::v-deep .ant-select {
  border-color: #343434 !important;
  /* 设置边框颜色为灰色 */
}

/* 定制 a-select 选项被选中时的样式 */
::v-deep .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: darken(#343434, 5%) !important;
  /* 选中项的背景色略暗 */
}


/* 可以添加其他样式 */
</style>

