<template>
  <a-layout-header style="padding: 0;">
    <div class="header-content">
      <img src="../assets/img/logos/roses2.png" alt="bug" style="width: 30px; height: 30px; margin-left: 20px;" />
      <div style="justify-content: end;">
        <a-button v-if="!walletConnected" ghost @click="connectToWallet" style="margin-right: 10px;">
          Connect wallet
        </a-button>
        <div v-else>
          <div class="icons-container">
        <div class="currency-dropdown-container" @click="handleCurrencyClick">
          <a class="currency-display">
            <span style="font-size: large; font-weight: bold; color: white; margin-right: 10px;">
              {{ selectedCurrency.amount }}
            </span>
            <img :src="selectedCurrency.icon" alt="Currency" />
            <a-icon type="down" />
          </a>
        </div>

        <img src="../assets/img/wallet2.png" alt="wallet" style="width: 30px; height: 30px; margin-right: 100px;" />
        <img src="../assets/img/human.png" @click="toggleDrawer" alt="user"
          style="width: 20px; height: 20px; margin-right: 30px;" />
      </div>

          <!--
            <BugOutlined style="margin-right: 50px; color: white; cursor: pointer;" @click="toggleDrawer" />
          -->
        </div>
      </div>
    </div>

  <!-- 在组件的合适位置添加 CurrencyModal 模态框 -->
  <a-modal 
    v-model:visible="isCurrencyModalVisible" 
    @cancel="closeCurrencyModal" 
    :footer="null"
    class="custom-modal">
    <CurrencyModal
      :selectedCurrency="selectedCurrency"
      :currencies="currencies"
      :closeCurrencyModal="closeCurrencyModal"
      :selectCurrency="selectCurrency"
      :selectedCurrencyKey="selectedCurrencyKey"
    />
  </a-modal>



   <!--
    <a-modal :footer="null" v-model:visible="isCurrencyModalVisible" :closable="false"  class="custom-modal">
      <template #title>
        <a-icon type="close" @click="closeCurrencyModal" />
      </template>
      <div class="currency-balance">
        结余
        <div style="font-size: x-large; color: #f1c24c;">{{ selectedCurrency.amount }} {{ selectedCurrency.key }}</div>
      </div>
      <div class="currency-withdrawal">
        <div>
          <div>可提款</div>
          <div style="font-size: large; color: white;">{{ selectedCurrency.withdrawable }} {{ selectedCurrency.key }}</div>
        </div>
        <div>
          <div>奖金</div>
          <div style="font-size: large; color: white;">{{ selectedCurrency.bonus }} {{ selectedCurrency.key }}</div>  
      </div>
      </div>
      <div class="currency-list-container">
      <a-list>
        <a-list-item
          v-for="currency in currencies"
          :key="currency.key"
          @click="selectCurrency(currency)"
          class="currency-list-item"
        >
          <span class="currency-amount">{{ currency.amount }}</span>
          <img :src="currency.icon" alt="Currency" class="currency-icon" />
          <span class="currency-key">{{ currency.key }}</span>
        </a-list-item>
      </a-list>
    </div>
  </a-modal>

   --> 


  </a-layout-header>

  <a-drawer placement="left" v-model:visible="drawerVisible" :closable="false"
    :drawerStyle="{ backgroundColor: 'black', color: 'black' }"
    :headerStyle="{ backgroundColor: 'black', borderBottom: 'none' }" :bodyStyle="{ border: 'none', boxShadow: 'none' }">
    <!-- 自定义 Drawer 标题 -->
    <template #title>
      <img src="../assets/img/logos/roses2.png" alt="Title Image" style="width: 80px; height: 80px; padding: 10px;" />
      <span style="font-size: 1.5em; font-weight: bold; color: white;">ROSIEGAME</span>
    </template>

    <a-menu mode="inline" style="border: none; font-size: 1.2em;background-color: black;color: white;font-weight: bold; "
      @click="onMenuItemClick">
      <a-menu-item key="wallet">Wallet</a-menu-item>
      <a-menu-item key="setting">Setting</a-menu-item>
      <a-menu-item key="support">Support</a-menu-item>
      <a-menu-item key="exit">Exit</a-menu-item>
    </a-menu>
  </a-drawer>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { connectWallet, checkWalletConnection } from '../wallet';
import { useStore } from 'vuex';
//import { BugOutlined } from '@ant-design/icons-vue';
import {  Menu, Icon } from 'ant-design-vue';
import CurrencyModal from './CurrencyModal.vue'; // 更新路径
import { message } from 'ant-design-vue';


export default {
  components: {
    //'a-modal': Modal,
    //'a-dropdown': Dropdown,
    'a-menu': Menu,
    'a-icon': Icon,
    //'a-list': List,
    //'a-list-item': List.Item,
    CurrencyModal
  },
  setup() {
    const isLoading = ref(false);
    const drawerVisible = ref(false); // 使用 ref 来声明响应式变量
    const store = useStore();
    const walletConnected = computed(() => store.state.walletConnected);

    // 货币列表数据
    const currencies = ref([
    { key: 'ROSIE', amount: '54585634.8', icon: require('../assets/img/logos/roses2.png'), withdrawable: '54585634.8', bonus: '54585634.8' },
    { key: 'BCH', amount: '0.00000001', icon: require('../assets/img/logos/bch_logo.png'), withdrawable: '0.00000001', bonus: '0.00000001' },
      { key: 'BNB', amount: '0.00000002', icon: require('../assets/img/logos/bnb_logo.png'), withdrawable: '0.00000002', bonus: '0.00000002' },
      { key: 'DOGE', amount: '0.00000003', icon: require('../assets/img/logos/doge_logo.png'), withdrawable: '0.00000003', bonus: '0.00000003' },
      { key: 'ETH', amount: '0.00000004', icon: require('../assets/img/logos/eth_logo.png'), withdrawable: '0.00000004', bonus: '0.00000004' },
      { key: 'LTC', amount: '0.00000005', icon: require('../assets/img/logos/ltc_logo.png'), withdrawable: '0.00000005', bonus: '0.00000005' },
      //{ key: 'MATIC', amount: '0.00000006', icon: require('../assets/img/logos/matic_logo.png'), withdrawable: '0.00000006', bonus: '0.00000006' },
      { key: 'TRX', amount: '0.00000007', icon: require('../assets/img/logos/trx_logo.png'), withdrawable: '0.00000007', bonus: '0.00000007' },
      { key: 'USDT', amount: '0.00000008', icon: require('../assets/img/logos/usdt_logo.png'), withdrawable: '0.00000008', bonus: '0.00000008' },
      { key: 'USDC', amount: '0.00000009', icon: require('../assets/img/logos/usdc_logo.png'), withdrawable: '0.00000009', bonus: '0.00000009' },
      { key: 'BTC', amount: '0.00000010', icon: require('../assets/img/logos/btc_logo.png'), withdrawable: '0.00000010', bonus: '0.00000010' },
  
      // ... 其他货币数据
    ]);

    // 当前选中的货币
    const selectedCurrency = ref(currencies.value[0]);
    const selectedCurrencyKey = ref(currencies.value[0].key);

    // 控制 Modal 的可见性
    const isCurrencyModalVisible = ref(false);

    // 点击货币显示下拉菜单
    const handleCurrencyClick = () => {
      // 可在这里做点击下拉的响应操作
      isCurrencyModalVisible.value = true;
    };

    // 选择货币的方法
    const selectCurrency = (currency) => {
      selectedCurrency.value = currency;

      selectedCurrencyKey.value = currency.key; // 更新选中的货币键
      // 可以在这里做更新余额的操作

    };

    // 关闭 Modal 的方法
    const closeCurrencyModal = () => {
      isCurrencyModalVisible.value = false;
    };

    const connectToWallet = async () => {
      isLoading.value = true;
      await connectWallet();
      isLoading.value = false;
    };

    const toggleDrawer = () => {
      drawerVisible.value = !drawerVisible.value; // 直接操作响应式引用
    };

    onMounted(() => {
      checkWalletConnection();
    });

    const onMenuItemClick = (item) => {
    switch (item.key) {
      case 'wallet':
        // 执行与 'Wallet' 相关的操作，比如导航到钱包页面
        message.info('Test Version');
        break;
      case 'setting':
        // 执行与 'Setting' 相关的操作，比如导航到设置页面
        message.info('Test Version');
        break;
      case 'support':
        // 执行与 'Support' 相关的操作，比如打开支持页面或弹出帮助对话框
        message.info('Test Version');
        break;
      case 'exit':
        // 执行与 'Exit' 相关的操作，比如退出账号或关闭应用
        message.info('Test Version');
        break;
      default:
        // 如果没有匹配的 key，可以在这里处理或者什么都不做
        message.info('Test Version');
    }
  };


    return {
      selectedCurrencyKey,
      currencies,
      selectedCurrency,
      isCurrencyModalVisible,
      handleCurrencyClick,
      selectCurrency,
      closeCurrencyModal,
      walletConnected,
      isLoading,
      connectToWallet,
      toggleDrawer,
      drawerVisible, // 将 drawerVisible 添加到 return 对象中，使其在模板中可用
      onMenuItemClick,
    };
  }
};
</script>

<style>

.custom-modal .ant-modal-content .ant-modal-body{
  background-color: rgb(20, 20, 20); /* 设置模态框背景颜色为黑色 */
}

.header-content{
  background-color: black;
  height: 80px;
  max-width: 768px;
}

/* 样式代码 */
.currency-display {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.currency-display img {
  width: 20px;
  height: 20px;
}

.icons-container {
  display: flex;
  align-items: center;
  /* 这将垂直居中所有项 */

}


.currency-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: gray;
  margin-bottom: 10px;
}

.currency-withdrawal {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: gray;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
}

.currency-withdrawal div {
  flex: 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.currency-dropdown-container {
  background-color: rgb(50, 50, 50); /* 灰色背景 */
  border-radius: 3px; /* 圆角 */
  padding: 5px 10px; /* 上下内边距设置为更小的值，左右保持原样 */
  display: flex; /* 使其子元素水平排列 */
  align-items: center; /* 垂直居中子元素 */
  /* margin-right: 10px; /* 右边距 */
  height: 30px;
}

.currency-list-container {
  column-count: 2; /* 创建两列 */
  column-gap: 20px; /* 列与列之间的间隙 */
}

.currency-list-item {
  break-inside: avoid-column; /* 防止列表项被拆分到不同列 */
  margin-bottom: 10px; /* 列表项之间的间隔 */
  display: flex;
  justify-content: space-between; /* 两端对齐 */
  align-items: center;
  background-color: black; /* 灰色背景 */
  border-radius: 5px; /* 圆角 */
  padding: 10px; /* 内边距 */
}

.currency-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px; /* 图标与文字间的间距 */
}

.currency-amount {
  margin-right: auto; /* 将金额推向左侧 */
  font-weight: bold;
  color: gray;
}

/* 防止图标和文本靠得太近 */
.currency-key, .currency-icon {
  white-space: nowrap;
  font-weight: bold;
  color: white;
}


</style>
