<template>
  <div class="container">
    <div style="display:flex;flex-direction:column;">
          <img src="../../assets/img/chri.avif" style="width:100%"/>

          <h2 style="color:white">这是ADS3</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
          <h2 style="color:white">在这个十二月与Lucky Block一起赢取每日奖励！</h2>
    </div>
  </div>

</template>
<script>

export default {
  components: {

  },
  setup() {
  }
}
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 每行显示三列 */
  /* grid-gap: 10px;图片之间的间隔 */
  grid-row-gap: 20px;
  /* 行间隔 */
  grid-column-gap: 10px;
  /* 列间隔是行间隔的两倍 */
  padding: 4%;
  max-width: 768px;

}

.image-grid img {
  width: 100%;
  /* 图片宽度自动适应父元素（每列）的宽度 */
  height: auto;
  /* 图片高度根据宽度自动调整，保持纵横比例 */
}

:deep(.slick-slide) {
  text-align: center;
  height: auto;
  /* 自动适应高度 */
  background: #000000;

}

:deep(.slick-slide img) {
  max-width: 100%;
  /* 图片宽度自动适应容器，不超过100% */
  max-height: 100vh;
  /* 图片高度最大为屏幕高度的100% */
}

:deep(.slick-slide h3) {
  color: #000000;
}

/* 使轮播图全屏宽度，并在移动设备上占满整个视口 */
@media (max-width: 768px) {
  :deep(.slick-slider) {
    width: 100vw;
    /* 宽度占满视口 */
    margin: 0;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-list) {
    margin: 10px;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-slide) {
    height: 220px;
    /* 每个轮播图幻灯片占满整个屏幕高度 */
  }

  :deep(.slick-slide img) {
    max-height: 100vh;
    /* 图片高度最大为屏幕高度的100% */
  }
}

.lgcintro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lgcintro .image {
  width: 40%;
  height: auto;
}

.lgcintro .text {
  width: 40%;
}

@media (max-width: 768px) {
  .lgcintro {
    flex-direction: column;
  }

  .lgcintro .image {
    width: 100%;
  }

  .lgcintro .text {
    width: 100%;
  }
}

.containerchart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: black;
}

:deep .ant-table {
  /*border: none;  隐藏表格外部边框 */
  margin: auto; /* 表格居中 */
  max-width: 95%; /* 表格最大宽度，保持与边界一定距离 */
}
/* 使用 ::v-deep 操作符 */
:deep .ant-table-thead > tr > th,
:deep .ant-table-tbody > tr > td {
  background: #000000 ; /* 统一设置背景色 */
  color: white ; /* 设置文字颜色 */
  border: none ; /* 隐藏单元格边框 */
}

:deep .ant-table-tbody > tr:nth-child(odd) > td {
  background: #1c1c1c ; /* 奇数行的背景色 */
}

:deep .ant-table-tbody > tr:nth-child(even) > td {
  background: #000000 ; /* 偶数行的背景色 */
}



</style>