<template>
  <!--
    <div v-if="isVisible" class="game-container" :class="{ 'fullscreen': isFullScreen }">
      <iframe :src="gameLink" frameborder="0" class="game-iframe"></iframe>
    </div>
  -->

  <div v-if="isVisible" class="game-container">
    <iframe :src="gameLink" frameborder="0" class="game-iframe"></iframe>
  </div>
  </template>
  
  <script>
  export default {
    props: {
      gameLink: String
    },
    data() {
      return {
        isVisible: true,
        //isFullScreen: true
      };
    },
    mounted() {
      //this.enterFullScreen();
      //document.addEventListener('fullscreenchange', this.onFullScreenChange);
    },
  }
    /*
    methods: {
      
      enterFullScreen() {
        let element = this.$el;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) { 
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { 
          element.msRequestFullscreen();
        }
      },
      onFullScreenChange() {
        if (!document.fullscreenElement) {
          this.isFullScreen = false; // 不再全屏时调整样式
        }
      }
    },
    beforeUnmount() {
      document.removeEventListener('fullscreenchange', this.onFullScreenChange);
    }
  }*/
  </script>
  
  <style scoped>
  .game-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background: black;
  }
  
  .fullscreen {
    width: 100%;
    height: 100%;
  }
  
  .game-iframe {
    width: 100%;
    height: 100%;
  }
  </style>
  