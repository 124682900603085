<template>
  <div class="app-wrapper">
    <a-layout id="components-layout-responsive">
      <a-layout>
        <only-header></only-header>
        <a-layout-content>
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <only-footer></only-footer>
  </div>
</template>


<script>
import { computed, onMounted, onUnmounted, reactive, toRefs, watch } from 'vue';
import OnlyHeader from "./components/OnlyHeader.vue";
import OnlyFooter from "./components/OnlyFooter.vue";
import { notification } from 'ant-design-vue';

import { useStore } from 'vuex';
import axios from 'axios';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'


export default {
  name: 'App',
  components: {
    //UnorderedListOutlined,
    //DownOutlined,
    //UpOutlined,
    OnlyFooter,
    OnlyHeader,
    //ResolveEventModal,
    //CreateEventModal,
  },
  setup() {

    let data = reactive({
      waitingbetData: null,
      showapproveBusy: false,
      showapproveModal: false,
      Approvevalue: 0,
      approvebalance: computed(() => (store.state.ApproveBalance)),
      collapsed: true,
      //isMobile: window.innerWidth < 768,
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      menuItems: [
        { key: '1', link: '/home', text: 'Home', comingSoon: false },
        { key: '2', link: '/events', text: 'Events', comingSoon: false },
        { key: '3', link: '/games', text: 'Games', comingSoon: false },
        { key: '4', link: '/earn', text: 'Earn', comingSoon: false },
        { key: '5', link: '/aifi', text: 'AIFI', comingSoon: false },
        { key: '7', link: '/dao', text: 'DAO', comingSoon: true },
      ],
      alertMessage: null,

      Choosevalue: 'Create',

      showChooseModal: false,
      showCreateModal: false,
      showResolveModal: false,
      walletConnected: false,
      popovervisible: false,
      upordown: false,
      account: '',
      balance: '',
      isadmin: false,
      walletType: computed(() => (data.walletConnected ? 'default' : 'primary')),
      buttonText: computed(() =>
        data.walletConnected ? `${data.account.slice(0, 6)}...${data.account.slice(-4)}` : 'Connect Wallet'
      ),
    });

    

    const projectId = '71f8c930711ed124c9f531456d835ba2';


    const mainnet = {
      chainId: 1,
      name: 'Ethereum',
      currency: 'ETH',
      explorerUrl: 'https://etherscan.io',
      rpcUrl: 'https://cloudflare-eth.com'
    }


    const metadata = {
      name: 'My Website',
      description: 'My Website description',
      url: 'https://mywebsite.com',
      icons: ['https://avatars.mywebsite.com/']
    }

    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [mainnet],
      projectId,
      themeVariables: {
        '--w3m-z-index': 1111,
      }
    })

    



    const store = useStore();
    data.walletConnected = computed(() => store.state.walletConnected);
    data.account = computed(() => store.state.Account);
    data.balance = computed(() => store.state.Balance);
    data.isadmin = computed(() => store.state.IsAdmin);


    const loginclick = () => {
      console.log('loginclick:::');
      //window.open("https://letmetagames.com/login");
    };
    const registclick = () => {
      console.log('registclick:::');
      //window.open("https://letmetagames.com/register");
    };

    const approveClick = () => {
      data.showapproveModal = true;
    };

    const approveOk = async () => {
      try {

        data.showapproveModal = false;
        data.showapproveBusy = true;

        const receipt = await store.dispatch('approve', data.Approvevalue);
        if (receipt && receipt.status === 1) {
          console.log('Transaction successful');

        } else {
          console.log('Transaction failed');
        }
        data.showapproveBusy = false;


        store.commit("setApproveBalance", data.Approvevalue);

        //data.approvebalance = data.Approvevalue;
        data.showapproveModal = false;
        console.log('approveOk:::', data.Approvevalue);
      } catch (error) {
        console.error('Error in approveOk:', error);
      }


    };

    const MEventsClick = () => {
      data.showChooseModal = true;
      data.popovervisible = false;
      console.log('MEventsClick:::', data.showChooseModal);
    };

    const handleChooseOk = () => {

      data.showChooseModal = false;
      switch (data.Choosevalue) {
        case 'Create':
          data.showCreateModal = true;
          break;
        case 'Resolve':
          get_ended_bets().then((result) => { // Use .then here
            data.waitingbetData = result;
            console.log('data.waitingbetData:::', data.waitingbetData);
            data.showResolveModal = true;
          });
          break;
        default:
          break;
      }
      //console.log('handleChooseOk:::', data.Choosevalue);

    };

    const get_ended_bets = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/bets/ended`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const handleVisibleChange = (popovervisible) => {
      console.log('Popover visibility changed:', popovervisible);
      // Additional logic here
      data.upordown = popovervisible;
    };
    watch(() => data.walletConnected, val => {
      data.upordown = val;
      console.log('data.upordown:::', val);
    });

    data.connectWallet = async () => {
      try {

        await store.dispatch('connectWallet');


        console.log('data.walletConnected:::', data.walletConnected);
        console.log('data.balance:::', data.balance);
        console.log('data.isadmin:::', data.isadmin);
        console.log('approve:::', store.state.ApproveBalance);
        //const { signer, account } = await web3Utils.connectWallet();
        //data.account = account;
        //data.balance = await web3Utils.getBalance(signer);
        //data.isadmin = await web3Utils.checkIsAdmin(signer, account);
        //data.walletConnected = true;

        // 调用 mutation 来更新钱包连接状态
        //store.commit('setWalletConnected', data.walletConnected);

      } catch (error) {
        console.error(error);
      }
    };

    data.disconnectWallet = () => {
      // To disconnect wallet, you need to manage it manually with your browser's wallet extension.
      // For MetaMask, the user will have to manually disconnect their wallet in the extension's settings

      store.commit("setWalletConnected", false);
      store.commit("setAccount", '');
      store.commit("setBalance", 0);
      store.commit("setIsAdmin", false);
      store.commit("setApproveBalance", 0);
      //data.walletConnected = false;
      //data.account = '';
      //data.balance = 0;
      //data.isadmin = false; // Add this line

      // 调用 mutation 来更新钱包连接状态
      //store.commit('setWalletConnected', data.walletConnected);
    };



    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMenuClick = (item) => {
      if (item.comingSoon) {
        //data.alertMessage = 'Coming soon';  // 如果是 coming soon 的菜单项，则设置警告信息
        notification['info']({
          message: 'INFO',
          description: 'Coming soon',
        });
        return false;  // 阻止事件的默认行为，防止页面跳转
      } else {
        scrollToTop();
        // 这里添加你需要执行的代码，比如页面跳转等
        data.alertMessage = '';  // 如果不是 coming soon 的菜单项，则清空警告信息
      }
    };


    //const isMobile = ref(window.innerWidth < 768);
    //const collapsed = ref(false);

    const updateIsMobile = (e) => {
      //data.isMobile = window.innerWidth < 768;
      data.isMobile = e.matches;
    };
    const mql = window.matchMedia('(max-width: 768px)');


    onMounted(() => {
      //window.addEventListener('resize', updateIsMobile);
      //updateIsMobile();
      mql.addListener(updateIsMobile);
    });

    onUnmounted(() => {
      //window.removeEventListener('resize', updateIsMobile);
      mql.removeListener(updateIsMobile);
    });

    const onCollapse = (collapsed) => {
      console.log(collapsed);
    };
    const handleClick = () => {
      console.log('图标被点击了!');
      data.collapsed = !data.collapsed;
      // 这里添加你点击图标之后需要执行的代码
    };
    const onBreakpoint = (broken) => {
      console.log(broken);
    };

    return {
      ...toRefs(data),
      onCollapse,
      onBreakpoint,
      handleClick,
      updateIsMobile,
      handleMenuClick,
      scrollToTop,
      //getBalance,
      handleVisibleChange,
      //checkIsAdmin,
      MEventsClick,
      handleChooseOk,
      approveClick,
      approveOk,
      get_ended_bets,
      loginclick,
      registclick,
      //alertMessage: data.alertMessage,  // 把 alertMessage 作为一个返回值，这样我们就可以在模板中访问它了
    };
  }
}
</script>
 
<style>
/* PC 端样式 */
@media (min-width: 769px) {
  body {
    background: url(./assets/img/bg.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .app-wrapper {
    max-width: 768px;
    /* 或者适合移动端显示的最大宽度 */
    margin: 0 auto;
    /* 居中显示 */
    /* 其他必要的样式 */
  }
}

#components-layout-responsive .logo {
  height: 32px;
  background: rgba(0, 0, 0, 0.2);
  margin: 16px;

}

#components-layout-responsive .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-responsive .trigger:hover {
  color: #1890ff;
}

#components-layout-responsive .header-content {
  position: fixed;
  /* Add this */
  width: 100%;
  /* Add this, to make sure it takes the full width */
  top: 0;
  /* Add this, to make sure it sticks to the top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #fff; */
  background: #000000;
  /* Add this, to make sure the header has a background */
  z-index: 999;
  /* Add this, to make sure the header is always on top */
}

.a-layout-content {
  margin-top: 64px;
  background: #000000;
  /* Add this, adjust this value according to your header height */
}

#components-layout-responsive .menu-control {
  border: none;
  box-shadow: none;
}
</style>