<template>
        <div class="currency-balance">
            Balance
            <div style="font-size: x-large; color: #f1c24c;">{{ selectedCurrency.amount }} {{ selectedCurrency.key }}</div>
        </div>
        <div class="currency-withdrawal">
            <div>
                <div>Withdrawal Available</div>
                <div style="font-size: large; color: white;">{{ selectedCurrency.withdrawable }} {{ selectedCurrency.key }}
                </div>
            </div>
            <div>
                <div>Bonus</div>
                <div style="font-size: large; color: white;">{{ selectedCurrency.bonus }} {{ selectedCurrency.key }}</div>
            </div>
        </div>
        <div class="currency-list-container">

            <a-list>
      <a-list-item
        v-for="currency in currencies"
        :key="currency.key"
        @click="selectCurrency(currency)"
        :class="{ 'selected-item': currency.key === selectedCurrencyKey }"
        class="currency-list-item">
        <span class="currency-amount">{{ currency.amount }}</span>
                    <img :src="currency.icon" alt="Currency" class="currency-icon" />
                    <span class="currency-key">{{ currency.key }}</span>
      </a-list-item>
    </a-list>


            <!--
            <a-list>
                <a-list-item v-for="currency in currencies" :key="currency.key" @click="selectCurrency(currency)"
                    class="currency-list-item">
                    <span class="currency-amount">{{ currency.amount }}</span>
                    <img :src="currency.icon" alt="Currency" class="currency-icon" />
                    <span class="currency-key">{{ currency.key }}</span>
                </a-list-item>
            </a-list>

            -->

        </div>

</template>
  
<script>
export default {
    props: {
        selectedCurrency: Object,
        currencies: Array,
        closeCurrencyModal: Function,
        selectCurrency: Function,
        selectedCurrencyKey: String, // 新增这一行
    },
    data() {

  },
    methods: {
    }
};
</script>
  
<style>
.custom-modal .ant-modal-content .ant-modal-body {
    background-color: rgb(20, 20, 20);
    /* 设置模态框背景颜色为黑色 */
}

.currency-balance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
    margin-bottom: 10px;
}

.currency-withdrawal {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: gray;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
}

.currency-withdrawal div {
    flex: 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.currency-list-container {
    column-count: 2;
    /* 创建两列 */
    column-gap: 20px;
    /* 列与列之间的间隙 */
}

.currency-list-item {
    break-inside: avoid-column;
    /* 防止列表项被拆分到不同列 */
    margin-bottom: 10px;
    /* 列表项之间的间隔 */
    display: flex;
    justify-content: space-between;
    /* 两端对齐 */
    align-items: center;
    background-color: black;
    /* 灰色背景 */
    border-radius: 5px;
    /* 圆角 */
    padding: 10px;
    /* 内边距 */
}

.currency-list-item.selected-item {
  background-color: #f1c24c; /* 浅黄色背景 */
}


.currency-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    /* 图标与文字间的间距 */
}

.currency-amount {
    margin-right: auto;
    /* 将金额推向左侧 */
    font-weight: bold;
    color: gray;
}

.currency-key {
    white-space: nowrap;
    font-weight: bold;
    color: white;
}</style>
  