<template>
  <div class="container">

    <div style="margin-top:0px; max-width: 768px;">
      <a-carousel autoplay>
        <div>
          <img src="../../assets/img/slide1.jpg" alt="Image Description 1" @click="navigateTo('/ads1')" />
        </div>
        <div>
          <img src="../../assets/img/slide2.jpg" alt="Image Description 2" @click="navigateTo('/ads1')" />
        </div>
        <div>
          <img src="../../assets/img/slide3.jpg" alt="Image Description 3" @click="navigateTo('/ads1')" />
        </div>
      </a-carousel>
      <a-tabs v-model:activeKey="activeKey" centered size="large" :tabBarStyle="{ color: 'gray' }">
        <a-tab-pane key="1">
          <template #tab>
            <span>
              <cluster-outlined />
              All Games
            </span>
          </template>
          <div class="image-grid">
            <img v-for="image in images" :key="image.id" :src="image.url" :alt="image.alt" @click="openGame(image.link)"
              style="border-radius: 5px; height: 150px;" />
          </div>
          
          <div class="pagination-container">
          <a-pagination 
          :current="currentPage" 
          :pageSize="pageSize" 
          :total="images.length" 
          @change="handlePageChange" />
          </div>

        
          <div class="game-container" v-if="isFullscreen">
            <FullScreenGame :gameLink="selectedGameLink" />
            <img src="@/assets/img/close-circle.png" alt="Close" class="close-btn" @click="hideGame" />
          </div>
          
          <!--
          <div class="fullscreen-game-container" v-if="isFullscreen">
            <FullScreenGame :gameLink="selectedGameLink" />
            <img src="@/assets/img/close-circle.png" alt="Close" class="fullscreen-close-btn" @click="hideGame" />
          </div>
          -->
          <!--
          <FullScreenGame v-if="selectedGameLink" :gameLink="selectedGameLink" @exit-fullscreen="hideGame" />
          -->
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <span>
              <money-collect-outlined />
              Supplier
            </span>
          </template>
          <a-empty :image="simpleImage" />
        </a-tab-pane>
      </a-tabs>

      <a-tabs class="ctabs" v-model:activeKey="activeKey2" centered size="large" :tabBarStyle="{ color: 'gray' }">
        <a-tab-pane key="1">
          <template #tab>
            <span>
              All Bet Amounts
            </span>
          </template>
          <a-table  :columns="gameColumns" :dataSource="gameData" :showPagination="false" :pagination="false" />
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <span>
              High Roller
            </span>
          </template>
          <a-table  :columns="winnerColumns" :dataSource="winnerData" :showPagination="false" :pagination="false" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted,computed  } from 'vue';
import { useRouter } from 'vue-router';

import axios from 'axios';


//import { useRouter } from 'vue-router';
//import PieChart from './components/PieChart.vue';
import { Empty } from 'ant-design-vue';
import { ClusterOutlined, MoneyCollectOutlined } from '@ant-design/icons-vue';

//import GameModal from '../../components/GameModal.vue';
import FullScreenGame from '../../components/FullScreenGame.vue';


export default {
  components: {
    //PieChart,
    ClusterOutlined,
    MoneyCollectOutlined,
    FullScreenGame
  },
  setup() {
    let data = reactive({
      isFullscreen: false, // 新增用于跟踪全屏状态的变量

      AirDropdata1: "A total of 1,000,000,000 LGC tokens are available VCs and those who meet the requirements for on-chain interaction and exchange activities will receive it.",
      AirDropdata2: "LGC tokens not claimed within 31 days will be used in the community's long-term incentive reward plan (except VCs). LGC will be distributed to top contributors in the Arbitrum community and destroyed.",
      claimnum: 0,
      images:[],
      /*
        images: [
        { id: 1, url: require('@/assets/img/kk1.jpeg'), alt: 'game1', link: 'http://game1.letmeta.io/' },
        { id: 2, url: require('@/assets/img/kk2.jpeg'), alt: 'game2', link: 'http://game2.letmeta.io/' },
        { id: 3, url: require('@/assets/img/kk3.jpeg'), alt: 'game3', link: 'http://game3.letmeta.io/' },
        { id: 4, url: require('@/assets/img/kk4.jpeg'), alt: 'game4', link: 'http://game4.letmeta.io/' },
        { id: 5, url: require('@/assets/img/kk5.jpeg'), alt: 'game5', link: 'http://game5.letmeta.io/' },
        { id: 6, url: require('@/assets/img/kk6.jpeg'), alt: 'game6', link: 'http://game6.letmeta.io/' },
      ],
      */
      gameColumns: [
        { title: 'Game', dataIndex: 'game', key: 'game' },
        { title: 'Bonus', dataIndex: 'winAmount', key: 'winAmount',align: 'right' },
      ],
      winnerColumns: [
        { title: 'Game', dataIndex: 'game', key: 'game' },
        { title: 'Bonus', dataIndex: 'winAmount', key: 'winAmount',align: 'right' },
      ],
      gameData: [], // 游戏数据
      winnerData: [], // 高额投注者数据
      activeKey: '1', // 例如，这里初始化为 '1'，你可以根据需要设置初始值
      activeKey2: '1', // 例如，这里初始化为 '1'，你可以根据需要设置初始值
      selectedGameLink: '',

      currentPage: 1,
      pageSize: 15, // 每页显示15个项目，根据您的需求调整
      datasessionKey: null,
      GameNames: ['Anubis','Halloween Night','Sexy Beach','Killer 23','Summer Beach','Lover Machine','Night Club','King of Fruit','Lovely Smile','After Dinner'],

      //selectedGameTitle: '',
    });
    const router = useRouter();
    const imagesContext = require.context('@/assets/img/gameimages', true);

    // 计算当前页的图片
    const currentImages = computed(() => {
      const start = (data.currentPage - 1) * data.pageSize;
      const end = start + data.pageSize;
      return data.images.slice(start, end);
    });

    function navigateTo(path) {
      router.push(path);
    }


    // 使用axios获取游戏数据
    const fetchGames = async () => {
      data.datasessionKey = sessionStorage.getItem('session'); // 从sessionStorage获取sessionKey
      if (!data.datasessionKey) {
        console.error("sessionKey is missing");
        data.datasessionKey = 'DFsDQNdcCY5S_IPX3xsHdA';
        //return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/getGames`, { sessionKey: data.datasessionKey });

        const gamesData = response.data;
        if (!Array.isArray(gamesData)) {
          throw new Error('Invalid games data format');
        }

        data.images = gamesData.map(game => {
          if (!game.GameKey || !game.Image || !game.Name || !game.gameurl) {
            throw new Error('Missing game data fields');
          }

          const imageName = game.Image.split('/').pop(); // 获取文件名
          const imagePath = imagesContext(`./${imageName}`); // 动态加载图片

          return {
            id: game.GameKey, // 或者其他唯一标识
            url: imagePath, // 游戏图片URL
            alt: game.Name, // 游戏名称
            link: game.gameurl // 游戏链接
          };
        });
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    // 模拟数据
    onMounted(() => {
      fetchGames();
      data.gameData = generateGameData(10, data.GameNames); // 传递 GameNames 作为参数
      data.winnerData = generateWinnerData(10, data.GameNames); // 生成10行高额投注者数据
    });
    // 模拟生成游戏数据

    // 在 setup 函数内部
    const generateGameData = (rows, gameNames) => {
      const data = [];
      for (let i = 0; i < rows; i++) {
        const gameNameIndex = i % gameNames.length; // 确保索引不会超出数组长度
        data.push({
          key: i + 1,
          game: gameNames[gameNameIndex],
          winAmount: `$${(Math.random() * 1000).toFixed(2)}`,
        });
      }
      return data;
    };
    // 打开游戏模态框的方法
    const openGame = (link) => {
      data.selectedGameLink = link;
      data.isFullscreen = true; // 设置为全屏
    };
    const hideGame = () => {
      //data.selectedGameLink = null;
      data.isFullscreen = false; // 退出全屏
      //router.push('/'); // 返回主页
    };

    // 模拟生成高额投注者数据
    function generateWinnerData(rows,gameNames) {
      const data = [];
      for (let i = 0; i < rows; i++) {
        const gameNameIndex = i % gameNames.length; // 确保索引不会超出数组长度
        data.push({
          key: i + 1,
          game: gameNames[gameNameIndex],
          winAmount: `$${(Math.random() * 1000).toFixed(2)}`,
        });
      }
      return data;
    }

    onMounted(() => {

    });
    return {
      ...toRefs(data),
      navigateTo,
      openGame,
      hideGame,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      generateGameData,
      generateWinnerData,
      currentImages, // 添加这个
      //gameColumns,
      //winnerColumns,

    };
  }
}
</script>

<style scoped>

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* 可以根据需要调整上边距 */
}


.game-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2001;
  width: 40px; 
  height: 40px;

}



.fullscreen-game-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 2000;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.fullscreen-close-btn {
  position: absolute;
  top: 20px; 
  right: 20px;
  z-index: 2001;
  cursor: pointer;
  width: 40px; 
  height: 40px;
}



.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 每行显示三列 */
  /* grid-gap: 10px;图片之间的间隔 */
  grid-row-gap: 20px;
  /* 行间隔 */
  grid-column-gap: 10px;
  /* 列间隔是行间隔的两倍 */
  padding: 4%;
  max-width: 768px;

}

.image-grid img {
  width: 100%;
  /* 图片宽度自动适应父元素（每列）的宽度 */
  height: auto;
  /* 图片高度根据宽度自动调整，保持纵横比例 */
}

:deep(.slick-slide) {
  text-align: center;
  height: auto;
  /* 自动适应高度 */
  background: #000000;

}

:deep(.slick-slide img) {
  max-width: 100%;
  /* 图片宽度自动适应容器，不超过100% */
  max-height: 100vh;
  /* 图片高度最大为屏幕高度的100% */
}

:deep(.slick-slide h3) {
  color: #000000;
}

/* 使轮播图全屏宽度，并在移动设备上占满整个视口 */
@media (max-width: 768px) {
  :deep(.slick-slider) {
    width: 100vw;
    /* 宽度占满视口 */
    margin: 0;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-list) {
    margin: 10px;
    /* 去除轮播图默认的外边距 */
  }

  :deep(.slick-slide) {
    height: 220px;
    /* 每个轮播图幻灯片占满整个屏幕高度 */
  }

  :deep(.slick-slide img) {
    max-height: 100vh;
    /* 图片高度最大为屏幕高度的100% */
  }
}

.lgcintro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lgcintro .image {
  width: 40%;
  height: auto;
}

.lgcintro .text {
  width: 40%;
}

@media (max-width: 768px) {
  .lgcintro {
    flex-direction: column;
  }

  .lgcintro .image {
    width: 100%;
  }

  .lgcintro .text {
    width: 100%;
  }
}

.containerchart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: black;
}

:deep .ant-table {
  /*border: none;  隐藏表格外部边框 */
  margin: auto; /* 表格居中 */
  max-width: 95%; /* 表格最大宽度，保持与边界一定距离 */
}
/* 使用 ::v-deep 操作符 */
:deep .ant-table-thead > tr > th,
:deep .ant-table-tbody > tr > td {
  background: #000000 ; /* 统一设置背景色 */
  color: white ; /* 设置文字颜色 */
  border: none ; /* 隐藏单元格边框 */
}

:deep .ant-table-tbody > tr:nth-child(odd) > td {
  background: #1c1c1c ; /* 奇数行的背景色 */
}

:deep .ant-table-tbody > tr:nth-child(even) > td {
  background: #000000 ; /* 偶数行的背景色 */
}



</style>