<template>
  <div class="container">
    <div>
      <div font-size="18px" color="text" style="color: rgb(23, 243, 221);
      font-weight: bold;
      margin-top: 50px;
      line-height: 1.5;
      font-family: ExtraBold;
      font-size: 2em;
      text-align: center;">Personal AI Companion</div>
    </div>

    <a-card class="custom-card">
      <div class="lgcintro">

        <div id="t1" class="text">
          <h1 style="font-weight: bold;font-size: large;">AI Companion</h1>
          <p style="font-size: medium;">Three levels of AI smart companions are provided: 1. Text, 2. Multimedia, 3. Peripherals. Private and exclusive AI computing power, hundreds of (authorized by real people) character cards to choose from, and users train each other and accompany them for a lifetime.</p>
          <a-button :disabled="true" size="large" shape="round" type="primary"
            style="margin-top: 20px; width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">LGC To Get</a-button>
        </div>
        <div id="t2" style="display: flex; flex-direction: column;align-items: center;">
          <img class="image" src="../../assets/img/aitotal.png" alt="Image Description" />
        </div>


      </div>
    </a-card>
    <!--
    <div style="font-size: large;font-weight: bold; text-align: left;max-width: 800px; width: 100%; margin-bottom: 20px;">
      Rules
    </div>


    <div style="max-width: 800px; width: 100%; text-align: left;">
      1. Stakers can upload game contracts and obtain the developer's income rights during the event.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      2. The calculation and storage of all games should be carried out on the blockchain, and games that require the host to provide auxiliary computing should be designed so that the user host can provide auxiliary computing in turn.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      3. The game contract code needs to be evaluated for safety.
    </div>
    -->
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
//import { useRouter } from 'vue-router';


export default {
  components: {
  },
  setup() {
    let data = reactive({
    });
    onMounted(() => {

    });
    return {
      ...toRefs(data),
    };
  }
}
</script>

<style scoped>
.lgcintro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lgcintro #t1 {
  flex: 0.6;
}

.lgcintro #t2 {
  flex: 0.4;
}

.lgcintro .image {
  width: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  /* 移动设备样式 */
  .lgcintro {
    flex-direction: column;
  }
  .lgcintro #t1 {
    order: 2;
  }
  .lgcintro #t2 {
    order: 1;
  }
  .lgcintro .image {
    width: 60%;
  }
}

.lgcintro .text {
  width: 100%;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.custom-card {
  margin: 10px;
  border-radius: 25px;
  overflow: auto;
  max-width: 800px;
  border: 1px solid rgb(202, 197, 197);
}
</style>
