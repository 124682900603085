<template>
  <div ref="chartRef" style="width: 100%; height: 200px;" />
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'

export default {
  setup() {
    const chartRef = ref(null);

    // Line chart options
    const lineOptions = {
      title: {
        show: false
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        show: false
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Developer',
          type: 'line',
          stack: 'total',
          areaStyle: {},
          data: [1, 1, 1, 1, 1, 1, 1]
        },
        {
          name: 'Gas',
          type: 'line',
          stack: 'total',
          areaStyle: {},
          data: [1, 1, 1, 1, 1, 1, 1]
        },
        {
          name: 'Stakers',
          type: 'line',
          stack: 'total',
          areaStyle: {},
          data: [1, 1, 1, 1, 1, 1, 1]
        }
      ]
    };

    const instance = getCurrentInstance();
    const $echarts = instance.appContext.config.globalProperties.$echarts;

    const initLineChart = () => {
      const chartInstance = $echarts.init(chartRef.value);
      chartInstance.setOption(lineOptions);
    };

    onMounted(() => {
      initLineChart();
    });

    return { chartRef };
  }
}
</script>
