<template>
  <div class="footer">
    <!--
    <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 15px;">
      <div style="width: 95%;height: 2px;background-color: whitesmoke"></div>
    </div>
    -->
    <div class="content-wrapper">

      <div class="gridpro">
        <a-row :gutter="16">
          <a-col :span="8">
            <h2 style="color: yellow;">Pledge Pool</h2>
          </a-col>
          <a-col :span="8">
            <h2 style="color: yellow;">Games</h2>
          </a-col>
          <a-col :span="8">
            <h2 style="color: yellow;">About Us</h2>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a @click="navigateToClick('DividendPledge')">Dividend Pledge</a>
          </a-col>
          <a-col :span="8">
            <a @click="navigateToClick('Slot')">Slot</a>
          </a-col>
          <a-col :span="8">
            <a @click="navigateToClick('PrivacyPolicy')">Privacy Policy</a>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a @click="navigateToClick('PrizePoolpledge')">Prize Pool pledge</a>
          </a-col>
          <a-col :span="8">
            <a @click="navigateToClick('Lucky')">Lucky</a>
          </a-col>
          <a-col :span="8">
            <a @click="navigateToClick('BonusTermsandConditions')">Bonus Terms and Conditions</a>
          </a-col>
        </a-row>
      </div>
    </div>
    <!--
    <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 15px;">
      <div style="width: 95%;height: 2px;background-color: whitesmoke"></div>
    </div>
    -->
    <div class="footer-text">
      letmeta.io is operated by Entretenimiento Rojo B.V., a company registered in the Commercial Register of Curaçao with number 152924 and address Emancipatie Boulevard Dominico F. 
      “Don” Martina 31, Willemstad, Curaçao. Entretenimiento Rojo B.V. is duly licensed through a , pursuant to Master Gaming License #5536/JAZ issued by the Governor of Curaçao.
    </div>

    <ul
      style="display: flex; justify-content: center; align-items: center; list-style: none; padding: 0; margin: 50px 0;">
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/f1.png" alt="Image Description 1" />
        </a>
      </li>
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/f3.svg" alt="Image Description 3" />
        </a>
      </li>
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/f2.svg" alt="Image Description 2" />
        </a>
      </li>
    </ul>

    <div class="footer-text">
      Support: help@letmeta.io | Complaints/Suggestions: complaints@letmeta.io | Affiliates: affiliates@letmeta.io
      <p></p>
      <p>© 2023 Arbitrum LetMeta. All right reserved.</p>
    </div>


    <ul
      style="display: flex; justify-content: center; align-items: center; list-style: none; padding: 0; margin: 50px 0;">
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/dis.svg" alt="Image Description 1" style="width: 30px; height: 30px;" />
        </a>
      </li>
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/tg.svg" alt="Facebook Icon" style="width: 30px; height: 30px;" />
        </a>
      </li>
      <li style="padding: 0 5px;">
        <a href="/" target="_blank">
          <img src="../assets/img/twt.svg" alt="Image Description 2" style="width: 30px; height: 30px;" />
        </a>
      </li>
    </ul>

    <!--
    <div style="display: flex;justify-content: center;margin-top: 50px;margin-bottom: 15px;">
      <div style="width: 95%;height: 2px;background-color: whitesmoke"></div>
    </div>
    <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 15px;">
      <div style="width: 95%;height: 2px;background-color: whitesmoke"></div>
    </div>
     -->
  </div>


  <!-- 确保fixed-footer-nav位于footer元素之外 -->
  <div class="fixed-footer-nav">
    <a-row align="middle" justify="space-around">
      <a-col :span="4" v-for="(item, index) in navItems" :key="item.key">
        <a @click="handleClick(item)" style="cursor: pointer;">
          <component :is="item.icon" :class="{ 'nav-icon': true, 'nav-icon-middle': index === 2 }" />
          <div>{{ item.text }}</div>
        </a>
      </a-col>
    </a-row>
  </div>


  <a-drawer placement="left" v-model:visible="drawerVisible" :closable=false
    :drawerStyle="{ backgroundColor: 'black', color: 'black' }"
    :headerStyle="{ backgroundColor: 'black', borderBottom: 'none' }" :bodyStyle="{ border: 'none', boxShadow: 'none' }">
    <!-- 自定义 Drawer 标题 -->
    <template #title>
      <img src="../assets/img/logos/roses2.png" alt="Title Image" style="width: 80px; height: 80px; padding: 10px;" />
      <span style="font-size: 1.5em; font-weight: bold; color: white;">ROSIEGAME</span>
    </template>

    <a-menu mode="inline" style="border: none; font-size: 1.2em;background-color: black;color: white;font-weight: bold; "
      @click="onMenuItemClick">
      <a-menu-item key="games">Games</a-menu-item>
      <a-menu-item key="sports">Sports</a-menu-item>
      <a-divider style="background-color: white; margin: 10px 0;"></a-divider>
      <a-menu-item key="bets">My Bets</a-menu-item>
      <a-menu-item key="crypto">Buy Cryptocurrency</a-menu-item>
      <a-menu-item key="rosie">Deposit with Rosie</a-menu-item>
      <a-menu-item key="buyrosie">How to buy Rosie</a-menu-item>
      <a-divider style="background-color: white; margin: 10px 0;"></a-divider>
      <a-menu-item key="promotions">Promotion</a-menu-item>
      <a-menu-item key="help">Help</a-menu-item>
    </a-menu>
  </a-drawer>


  <a-modal 
    v-model:visible="isModalVisible" 
    @cancel="handleCancel" 
    :footer="null"
    class="custom-modal"
  >
    <RegLogin @loginSuccess="handleLoginSuccess" @registerSuccess="handleRegisterSuccess" />
  </a-modal>

  <!-- RegLogin Modal -->
  <a-modal 
    v-model:visible="isRegLoginModalVisible" 
    @cancel="handleRegLoginCancel" 
    :footer="null"
    class="custom-modal">
    <RegLogin />
  </a-modal>

  <!-- 在组件的合适位置添加 WalletInfo 模态框 -->
  <a-modal 
    v-model:visible="isWalletInfoModalVisible" 
    @cancel="handleWalletInfoCancel" 
    :footer="null"
    class="custom-modal">
    <WalletInfo />
  </a-modal>

</template>

<script>

import RegLogin from './RegLogin.vue'; // 确保路径正确
import WalletInfo from './WalletInfo.vue'; // 确保路径正确

import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { WalletOutlined, MenuOutlined, FormOutlined, SlidersOutlined, DribbbleOutlined, RadarChartOutlined } from '@ant-design/icons-vue';

import { message } from 'ant-design-vue';

export default {
  components: {
    RegLogin,WalletInfo,WalletOutlined, MenuOutlined, FormOutlined, SlidersOutlined, DribbbleOutlined, RadarChartOutlined
  },
  setup() {
    const router = useRouter();
    // 使用一个对象来包含所有响应式变量
    const data = {
      drawerVisible: ref(false),
      isWalletInfoModalVisible: ref(false),
      isLoggedIn: ref(true), // 根据实际逻辑设置
      isModalVisible : ref(false), // 定义状态
      isRegLoginModalVisible : ref(false), // 定义状态
    };

    const navItems = computed(() => {
      let items = [
        { key: 'menu', path: '/menu', icon: MenuOutlined, text: 'menu', action: menuClick },
        { key: 'sports', path: '/sports', icon: DribbbleOutlined, text: 'sports', action:sportsClick  },
        { key: 'pledge', path: '/pledge', icon: SlidersOutlined, text: 'pledge' },
        { key: 'games', path: '/home', icon: RadarChartOutlined, text: 'home' },
      ];

      if (data.isLoggedIn.value) {
        items.push({ key: 'wallet', path: '/wallet', icon: WalletOutlined, text: 'wallet', action: showWalletInfoModal});
      } else {
        items.push({ key: 'register', path: '/register', icon: FormOutlined, text: 'connect', action: showRegLoginModal });
      }

      return items;
    });

    const menuClick=() =>{
      data.drawerVisible.value = true;
    };
    const sportsClick=() =>{
      message.info('Not open yet');
    };
    const showRegLoginModal=() =>{
      data.isRegLoginModalVisible.value = true; // 显示注册登录模态框
    };

    const onMenuItemClick = (item) => {
    switch (item.key) {
      case 'games':
        message.info('Test Version');
        break;
      case 'sports':
        message.info('Test Version');
        break;
      case 'bets':
        message.info('Test Version');
        break;
      case 'crypto':
        message.info('Test Version');
        break;
      case 'rosie':
        message.info('Test Version');
        break;
      case 'buyrosie':
        message.info('Test Version');
        break;
      case 'promotions':
        message.info('Test Version');
        break;
      case 'help':
        message.info('Test Version');
        break;
      default:
        message.info('Test Version');
    }
  };



    const handleRegLoginCancel=() =>{
      data.isRegLoginModalVisible.value = false; // 关闭模态框
    };


    const showWalletInfoModal=() =>{
      data.isWalletInfoModalVisible.value = true; // 显示钱包信息模态框
    };
    const handleWalletInfoCancel=() =>{
      data.isWalletInfoModalVisible.value = false; // 关闭模态框
    };

    const handleClick = (item) => {

    // 先滚动到页面顶部
    window.scrollTo(0, 0);

    if (item.action) {
      item.action();
    } else {
      router.push(item.path);
    }
    };


    const navigateToClick = (item) => {
      console.log(item);
      message.info('Test Version');
    };

    return {

      ...data,
      navItems,
      handleClick,
      menuClick,
      sportsClick,
      showRegLoginModal,
      showWalletInfoModal,
      handleWalletInfoCancel,
      handleRegLoginCancel,
      onMenuItemClick,
      navigateToClick
    };
  }
};
</script>

<style>
.custom-modal .ant-modal-content {
  background-color: black; /* 设置模态框背景颜色为黑色 */
}
.custom-modal .ant-modal-close-icon {
  color: white; /* 设置关闭图标的颜色为白色 */
}
.footer {
  /*
  margin-top: 40px;
  */
  background-color: black;
  /* 确保 footer 的内容不会被 fixed-footer-nav 遮挡 */
  padding-bottom: 60px;
  /* 底部内边距等于或大于 fixed-footer-nav 的高度 */
}

.footer-text {
  text-align: center;
  margin: 50px 10px 10px 10px;
  color: white;
  font-size: 0.8em;
  font-weight: bold;

}

.content-wrapper {
  display: flex;
  flex-direction: column;
}

.logodes {
  margin: 30px 0 0 20px;
  /* 添加这行，使它们在小屏幕上占据全部宽度 */
}

.gridpro {
  margin: 30px 0 0 20px;
  /*margin-top: 30px;*/
  /* 添加这行，使它们在小屏幕上占据全部宽度 */
}

.fixed-footer-nav {
  box-sizing: border-box;
  /* 确保内边距不会导致宽度溢出 */
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  z-index: 1000;
  /* 确保没有 margin 或 padding 影响定位 */
  margin: 0;
  padding: 10px 0;
  max-width: 768px;
  /* 或者适合移动端显示的最大宽度 */
}

.nav-icon {
  font-size: 24px;
  /* Adjust size of Ant Design Vue icons */
  color: #fff;
  /* Adjust color of Ant Design Vue icons */
}

.fixed-footer-nav div {
  text-align: center;
  margin-top: 4px;
  color: white;
  /* Text color */
}

.fixed-footer-nav .router-link-active {
  color: yellow;
  /* Active link color */
}

.nav-icon {
  font-size: 24px;
  /* Adjust size of Ant Design Vue icons */
  color: #fff;
  /* Default color of Ant Design Vue icons */
}

.nav-icon-middle {
  color: yellow !important;
  /* Set the middle icon color to yellow */
}</style>