<template>
    <div class="container">
      <div style="display:flex;flex-direction:column; color:white">
        <h1>隐私政策</h1>
        <p dir="ltr">1.&nbsp; &nbsp; &nbsp; 引言</p>
        <p dir="ltr">2.&nbsp; &nbsp; &nbsp; 本隐私声明的目的</p>
        <p dir="ltr">3.&nbsp; &nbsp; &nbsp; 我们是谁以及如何联系我们</p>
        <p dir="ltr">4.&nbsp; &nbsp; &nbsp; 隐私声明的更改</p>
        <p dir="ltr">5.&nbsp; &nbsp; &nbsp; 第三方链接</p>
        <p dir="ltr">6.&nbsp; &nbsp; &nbsp; 我们收集关于你的数据</p>
        <p dir="ltr">7.&nbsp; &nbsp; &nbsp; 如果你未能提供个人数据</p>
        <p dir="ltr">8.&nbsp; &nbsp; &nbsp; 如何收集你的个人数据</p>
        <p dir="ltr">9.&nbsp; &nbsp; &nbsp; 我们如何使用你的个人数据</p>
        <p dir="ltr">10.&nbsp; 营销</p>
        <p dir="ltr">11.&nbsp; 披露你的个人数据</p>
        <p dir="ltr">12.&nbsp; 数据安全</p>
        <p dir="ltr">13.&nbsp; 数据保留期</p>
        <p dir="ltr">14.&nbsp; 你的权利</p>

        <p dir="ltr">我们尊重你的隐私，并致力于保护你的个人数据。当你访问本网站、注册Lucky Block账户或通过任何其他方式（例如通过电话）提供个人数据时，本隐私声明将告知你我们如何处理你的个人数据。它还涵盖了我们从第三方和公开来源（如社交媒体平台、数据聚合器和信用参考机构）收集的个人数据。它规定了你的隐私权以及法律如何保护它们。</p>



      </div>
    </div>
  
  </template>
  <script>
  export default {
    components: {
  
    },
    setup() {
    }
  }
  </script>
  
  <style scoped>
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 每行显示三列 */
    /* grid-gap: 10px;图片之间的间隔 */
    grid-row-gap: 20px;
    /* 行间隔 */
    grid-column-gap: 10px;
    /* 列间隔是行间隔的两倍 */
    padding: 4%;
    max-width: 768px;
  
  }
  
  .image-grid img {
    width: 100%;
    /* 图片宽度自动适应父元素（每列）的宽度 */
    height: auto;
    /* 图片高度根据宽度自动调整，保持纵横比例 */
  }
  
  :deep(.slick-slide) {
    text-align: center;
    height: auto;
    /* 自动适应高度 */
    background: #000000;
  
  }
  
  :deep(.slick-slide img) {
    max-width: 100%;
    /* 图片宽度自动适应容器，不超过100% */
    max-height: 100vh;
    /* 图片高度最大为屏幕高度的100% */
  }
  
  :deep(.slick-slide h3) {
    color: #000000;
  }
  
  /* 使轮播图全屏宽度，并在移动设备上占满整个视口 */
  @media (max-width: 768px) {
    :deep(.slick-slider) {
      width: 100vw;
      /* 宽度占满视口 */
      margin: 0;
      /* 去除轮播图默认的外边距 */
    }
  
    :deep(.slick-list) {
      margin: 10px;
      /* 去除轮播图默认的外边距 */
    }
  
    :deep(.slick-slide) {
      height: 220px;
      /* 每个轮播图幻灯片占满整个屏幕高度 */
    }
  
    :deep(.slick-slide img) {
      max-height: 100vh;
      /* 图片高度最大为屏幕高度的100% */
    }
  }
  
  .lgcintro {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .lgcintro .image {
    width: 40%;
    height: auto;
  }
  
  .lgcintro .text {
    width: 40%;
  }
  
  @media (max-width: 768px) {
    .lgcintro {
      flex-direction: column;
    }
  
    .lgcintro .image {
      width: 100%;
    }
  
    .lgcintro .text {
      width: 100%;
    }
  }
  
  .containerchart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: black;
  }
  
  :deep .ant-table {
    /*border: none;  隐藏表格外部边框 */
    margin: auto; /* 表格居中 */
    max-width: 95%; /* 表格最大宽度，保持与边界一定距离 */
  }
  /* 使用 ::v-deep 操作符 */
  :deep .ant-table-thead > tr > th,
  :deep .ant-table-tbody > tr > td {
    background: #000000 ; /* 统一设置背景色 */
    color: white ; /* 设置文字颜色 */
    border: none ; /* 隐藏单元格边框 */
  }
  
  :deep .ant-table-tbody > tr:nth-child(odd) > td {
    background: #1c1c1c ; /* 奇数行的背景色 */
  }
  
  :deep .ant-table-tbody > tr:nth-child(even) > td {
    background: #000000 ; /* 偶数行的背景色 */
  }
  
  
  
  </style>