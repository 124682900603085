<template>
  <div class="container">
    <div>
      <div font-size="18px" color="text" style="color: rgb(23, 243, 221);
      font-weight: bold;
      margin-top: 50px;
      line-height: 1.5;
      font-family: ExtraBold;
      font-size: 2em;
      text-align: center;">The List of LetGames</div>
    </div>

    <a-card class="custom-card">
      <div class="lgcintro">

        <div class="text">
          <h1 style="font-weight: bold;font-size: large;">Texas Hold'em</h1>
          <p style="font-size: medium;">The completely decentralized Texas Hold'em makes the game itself reflect the ultimate fairness. The community aims to allow 10,000 people to play decentralized Texas Hold'em online at the same time.</p>
          <a-button :disabled="true" size="large" shape="round" type="primary"
            style="margin-top: 20px; width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">Join Game</a-button>
        </div>
        <div style="display: flex; align-items: center;flex-direction: column;">
          <img class="image" src="../../assets/img/TH.png" alt="Image Description" />
        <div>
          <img style="width: 20px;" src="../../assets/img/arbitrum.png" alt="arbitrum" />
          <img style="width: 20px;" src="../../assets/img/chainlink.png" alt="chainlink" />
          <img style="width: 20px;" src="../../assets/img/zkSync.png" alt="zkSync" />
        </div>
        </div>


      </div>
    </a-card>

    <div style="font-size: large;font-weight: bold; text-align: left;max-width: 800px; width: 100%; margin-bottom: 20px;">
      Rules
    </div>


    <div style="max-width: 800px; width: 100%; text-align: left;">
      1. Stakers can upload game contracts and obtain the developer's income rights during the event.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      2. The calculation and storage of all games should be carried out on the blockchain, and games that require the host to provide auxiliary computing should be designed so that the user host can provide auxiliary computing in turn.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      3. The game contract code needs to be evaluated for safety.
    </div>

  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
//import { useRouter } from 'vue-router';


export default {
  components: {
  },
  setup() {
    let data = reactive({
    });
    onMounted(() => {

    });
    return {
      ...toRefs(data),
    };
  }
}
</script>

<style scoped>
.lgcintro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lgcintro .image {
  width: 60%;
}

@media screen and (max-width: 767px) {
  /* 移动设备样式 */
  .lgcintro .image {
    width: 100%;
  }
}

.lgcintro .text {
  width: 60%;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.custom-card {
  margin: 10px;
  border-radius: 25px;
  overflow: auto;
  max-width: 800px;
  border: 1px solid rgb(202, 197, 197);
}</style>