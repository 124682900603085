<template>
  <div class="container">
    <div>
      <div font-size="18px" color="text" style="color: rgb(23, 243, 221);
        font-weight: bold;
        margin-top: 50px;
        line-height: 1.5;
        font-family: ExtraBold;
        font-size: xx-large;
        text-align: center;">Staking to Earn</div>
    </div>

    <a-card class="rounded-card" :style="{ margin: '20px', width: '95%', maxWidth: '800px' }">
      <!-- Title -->
      <a-row :gutter="16">
        <a-col :span="24">
          <h2 class="centered-text">LGC Staking Statistics</h2>
        </a-col>
      </a-row>
      <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 10px;">
        <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
      </div>
      <!-- Row with 2 columns without split -->
      <a-row :gutter="16">
        <a-col :xs="24" :sm="24">
          <div style="display: flex;justify-content: space-between;">
            <div style="display:flex;flex-direction: column;justify-content: center;width: 50%;text-align: center;">
              <span style="font-size: large;color: gray;">Current Staking</span>
              <span style="font-size: large;font-weight: bold;">0,000.00</span>
            </div>
            <div style="display:flex;flex-direction: column;justify-content: center;width: 50%;text-align: center;">
              <span style="font-size: large;color: gray;">APY</span>
              <span style="font-size: large;font-weight: bold;">0.00%</span>
            </div>
          </div>
        </a-col>
      </a-row>

      <div style="display: flex;justify-content: center;margin-top: 15px;margin-bottom: 10px;">
        <div style="width: 100%;height: 2px;background-color: whitesmoke"></div>
      </div>

      <a-row :gutter="16">
        <a-col :xs="24" :sm="12">
          <div id="c1"
            style="display:flex;flex-direction: column;justify-content: center; align-items: center; width: 100%;text-align: center;">
            <span style="width: 100%; text-align: left;font-size: large;color: gray;">My Staking</span>
            <a-image :width="100" :preview="false" :src="require('../../assets/img/LILI.png')" />
            <span style="font-size: large;font-weight: bold;">0,000.00 LGC</span>
            <span><a-button :disabled="true" type="primary" shape="round"
                style="margin-top: 20px; width: 300px;height: 50px;">Start Staking</a-button></span>
          </div>
        </a-col>
        <a-col :xs="24" :sm="12">
          <div id="c2"
            style="display:flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;text-align: center;">
            <span style="width: 100%; text-align: left;font-size: large;color: gray;">My Rewards</span>
            <a-image :width="100" :preview="false" :src="require('../../assets/img/LILI.png')" />
            <span style="font-size: large;font-weight: bold;">0,000.00 LGC</span>
            <span><a-button :disabled="true" shape="round" style="margin-top: 20px; width: 300px;height: 50px;">Claim
                Rewards</a-button></span>
          </div>
        </a-col>
      </a-row>
    </a-card>


    <div style="max-width: 800px; width: 100%; text-align: left;">
      1. The pledger's income calculation starts at UTC+0, and the pledge income is distributed for the previous 24 hours
      at UTC+0 every day.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      2. Earnings pledges are different from game and event creation pledges, and they belong to different smart contract
      management.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;">
      3. The pledge and recharge time will be delayed according to network congestion and Gas level.
    </div>
    <div style="max-width: 800px; width: 100%; text-align: left;color: red;font-weight: bold;margin-top: 20px;">
      Note: No god can guarantee the rate of return on staking.
    </div>

    <div style="font-size: large;margin-top: 30px;">One Week Earned</div>
    <div id="lchart">
      <LineChart />
    </div>



  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
//import { useRouter } from 'vue-router';

import LineChart from './components/LineChart.vue';



export default {
  components: {
    LineChart,
  },
  setup() {
    let data = reactive({

    });
    onMounted(() => {

    });
    return {
      ...toRefs(data),
    };
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.rounded-card {
  border-radius: 15px;
  /* Apply round corner */
}

.centered-text {
  text-align: center;
}

.lefted-text {
  text-align: left;
}

.righted-text {
  text-align: right;
}

/* ID-based selectors for the divs */
#c1 {
  border-right: 1px solid #ddd;
  border-bottom: 0;
}

#lchart{
  width: 800px;
}
/* Media query for screens smaller than 768px (usually mobile) */
@media screen and (max-width: 768px) {
  #c1 {
    border-right: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  #lchart{
  width: 100%;
  }
}
</style>