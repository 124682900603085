import { ethers, formatEther} from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from "@ethersproject/bignumber";

import LGCCoinContract from '../../../LGC_Arbitrum_Goerli_Coins/artifacts/contracts/TestLetGamesCoin.sol/TestLetGamesCoin.json';
import LGCBettingContract from '../../../LGC_Arbitrum_Goerli_Betting_Contracts/artifacts/contracts/Betting.sol/Betting.json';

const coinContractAddress = '0x1677a26a9911D5cFc208E1A17d9f474b3105D923';
const bettingContractAddress = '0x97a3D07Ec2eDC43aeCf15a38388D5a952d72939b';

export const getAllowance = async (signer, owner) => {
    const coinContract = new ethers.Contract(coinContractAddress, LGCCoinContract.abi, signer);
    const allowance = await coinContract.allowance(owner, bettingContractAddress);
    return BigNumber.from(allowance).div(BigNumber.from(10).pow(18)).toString();
};

export const approve = async (signer, amount) => {
    const coinContract = new ethers.Contract(coinContractAddress, LGCCoinContract.abi, signer);
    const weiAmount = BigNumber.from(amount).mul(BigNumber.from(10).pow(18)); 
    const transactionResponse = await coinContract.approve(bettingContractAddress, weiAmount.toString());
    const transactionHash = transactionResponse.hash;
    const provider = await getProvider();

    return new Promise((resolve, reject) => {
        provider.on(transactionHash, (receipt) => {
            console.log('Transaction has been mined');
            console.log('Transaction receipt: ', receipt);
            provider.off(transactionHash);
            resolve(receipt);  // Resolve the promise with the receipt
        });

        // Handle errors - this may not be necessary depending on your use case
        provider.on('error', reject);
    });
};


export const createBetOnChain = async (signer, betId, description, end_time, fee_percentage, oracle, oracle_data) => {
    try {
        const contract = getBettingContract(signer);

        const betIdNumber = Number(betId);
        const endTimeNumber = Number(end_time);
        const feePercentageNumber = Number(fee_percentage);

        const transactionResponse = await contract.createBet(
            betIdNumber,
            description,
            endTimeNumber,
            feePercentageNumber,
            oracle,
            oracle_data
        );

        return transactionResponse;
    } catch (error) {
        console.error('Error creating event on-chain:', error);
    }
};

export const getBettingContract = (signer) => {
    return new ethers.Contract(bettingContractAddress, LGCBettingContract.abi, signer);
};
export const isBetCreated = async (signer, _betId) => {
    const bettingContract = new ethers.Contract(bettingContractAddress, LGCBettingContract.abi, signer);
    return bettingContract.isBetCreated(_betId);
};
export const connectWallet = async () => {
    if (window.ethereum) {
        await window.ethereum.enable();
        const provider = new Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        return { signer, account: await signer.getAddress() };
    } else {
        throw new Error('Please install MetaMask!');
    }
};

export const getSigner = async () => {
    if (window.ethereum) {
        const provider = new Web3Provider(window.ethereum);
        return provider.getSigner();
    } else {
        throw new Error('Please install MetaMask!');
    }
};

// 添加新的函数 getProvider
export const getProvider = async () => {
    if (window.ethereum) {
        return new Web3Provider(window.ethereum);
    } else {
        throw new Error('Please install MetaMask!');
    }
};


export const getBalance = async (signer) => {
    const coinContract = new ethers.Contract(coinContractAddress, LGCCoinContract.abi, signer);
    const balanceWei = await coinContract.balanceOf(await signer.getAddress());
    return formatEther(balanceWei);
};

export const checkIsAdmin = async (signer, account) => {
    const bettingContract = new ethers.Contract(bettingContractAddress, LGCBettingContract.abi, signer);
    return bettingContract.hasRole('0x0000000000000000000000000000000000000000000000000000000000000000', account);
};

export const vote = async (signer, _betId, _vote, _amount) => {
    const bettingContract = getBettingContract(signer);
    const weiAmount = BigNumber.from(_amount).mul(BigNumber.from(10).pow(18)); // 使用 BigNumber.from 和 mul 将 ether 转换为 wei
    await bettingContract.vote(_betId, _vote, weiAmount.toString());
};


export const resolveBetAndclaimReward = async (signer, _betId, _result) => {
    try {
        const bettingContract = getBettingContract(signer);
        const provider = await getProvider();

        // 首先解决投注
        const transactionResponseResolve = await bettingContract.resolveBet(_betId, _result);
        console.log(`resolveBet交易已发送，交易哈希为: ${transactionResponseResolve.hash}`);

        return new Promise((resolve, reject) => {
            // 挖矿成功事件
            provider.on(transactionResponseResolve.hash, async (receipt) => {
                console.log('resolveBet交易已被挖矿');
                console.log('交易收据: ', receipt);
                provider.off(transactionResponseResolve.hash);

                // 在投注解决后，要求奖励
                const transactionResponseClaim = await bettingContract.claimReward(_betId);
                console.log(`claimReward交易已发送，交易哈希为: ${transactionResponseClaim.hash}`);
                
                provider.on(transactionResponseClaim.hash, (receipt) => {
                    console.log('claimReward交易已被挖矿');
                    console.log('交易收据: ', receipt);
                    provider.off(transactionResponseClaim.hash);
                    resolve(receipt); // 使用收据解决Promise
                });

                // claimReward交易错误事件
                provider.once('error', error => {
                    console.error(`在claimReward交易中出现错误: ${error}`);
                    provider.off(transactionResponseClaim.hash);
                    reject(error);
                });
            });

            // resolveBet交易错误事件
            provider.once('error', error => {
                console.error(`在resolveBet交易中出现错误: ${error}`);
                provider.off(transactionResponseResolve.hash);
                reject(error);
            });
        });

    } catch (error) {
        console.error('解决投注并要求奖励时出错:', error);
    }
};

