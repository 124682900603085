<template>
  <div class="reg-login-container">
    <a-tabs centered v-model:activeKey="activeKey">
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div style="margin: 5px; display: flex; align-items: center;">
            <a-button ghost @click="activeKey = 'linkwallet'" style="color: white; margin-bottom: 10px;">
              <LinkOutlined style="color: blue;" /> Connect Wallet
            </a-button>
          </div>
        </div>
    </a-tabs>
  </div>
</template>
  
<script>
import { LinkOutlined } from '@ant-design/icons-vue';


export default {
  components: {
    LinkOutlined,
  },
  setup() {


    return {

    };
  }
};
</script>
<style scoped>
.reg-login-container {
  background-color: black;
  /* 背景颜色 */
  padding: 20px;
  max-width: 500px;
  /* 最大宽度 */
  margin: auto;
  /* 水平居中 */

}

.full-width-button {
  width: 100%;
  /* 按钮宽度拉满 */
  background-color: #f1c24c;
  /* 按钮背景颜色 */
  color: black;
  /* 文字颜色 */
}

.form-style {
  margin-top: 20px;
}

/* 使用 ::v-deep 选择器定制 Ant Design Vue Form Item 组件的标签样式 */
::v-deep .ant-form-item-label>label {
  color: white;
  /* 设置标签字体颜色为白色 */
}

/* 定制 Tabs 标签的样式 */
/* 定制 Tabs 标签的样式 */
/* 使用 ::v-deep 选择器定制 Ant Design Vue Tabs 组件样式 */
::v-deep .ant-tabs-tab {
  color: gray;
  font-weight: bold;
  font-size: large;
}

::v-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

::v-deep .ant-tabs-content {
  color: white;
}


/* 可以添加其他样式 */
</style>

